import React, { useContext } from 'react'
import { styled } from 'linaria/react'
import { formatPrice } from '../Util/Helpers'
import { SiteContext } from '../../Global/SiteContext'
import UseFormatProduct from '../Hooks/UseFormatProduct'
import { useIntl } from '@jetshop/intl'

const PriceCardSectionContainer = styled.div`
   display:flex;
   flex-wrap: wrap;
   align-items: baseline;
   column-gap: 0.6rem;

   .product-card-price {
      font-size: 1rem;
      font-weight: 700;
      font-family: var(--font-industry);
   }

   .product-card-saleprice {
      color: #a61200;
   }

   .product-card-price-regular {
      text-decoration: line-through;
   }

   .vat {
      font-weight: 500;
      font-size: 12px;
      font-family: var(--font-industry);
   }
`

export function PriceSection({
   product,
   selectedChannel,
   showVatIndicator,
   className,
   overrideWrapperClass
}) {
   const { showVat } = useContext(SiteContext)
   const t = useIntl()

   let { currentPrice, oldPrice, exVatCurrentPrice, exVatOldPrice, isPackage, isPackageItem, hasVariants } =
      UseFormatProduct(product)

   const renderPackagePrice = () => {
      return (
         <PriceCardSectionContainer className={overrideWrapperClass ? overrideWrapperClass : `col-12 pe-0 ${className}`}>
            <span
               className="product-card-price lh-base"
            >
               {formatPrice(
                  showVat ? currentPrice : exVatCurrentPrice,
                  selectedChannel?.id
               )}
            </span>
            {oldPrice && (
               <span className="product-card-price-regular product-card-price">
                  {formatPrice(
                     showVat ? oldPrice : exVatOldPrice,
                     selectedChannel?.id
                  )}
               </span>
            )}
         </PriceCardSectionContainer>
      )
   }

   if(isPackage){
      return renderPackagePrice()
   }

   return (
      <PriceCardSectionContainer className={overrideWrapperClass ? overrideWrapperClass : `col-12 pe-0 ${className}`}>
         <span
            className={`product-card-price lh-base${
               (oldPrice && (!isPackageItem && !isPackage)) ? ' product-card-saleprice' : ''
            }`}
         >
            {formatPrice(
               showVat ? currentPrice : exVatCurrentPrice,
               selectedChannel?.id
            )}
         </span>
         {!showVat && !exVatCurrentPrice && (
            <span className="product-card-price lh-base">
               {t('For price excl VAT see the product page')}
            </span>
         )}
         {oldPrice && (
            <span className="product-card-price-regular product-card-price">
               {formatPrice(
                  showVat ? oldPrice : exVatOldPrice,
                  selectedChannel?.id
               )}
            </span>
         )}
         {showVatIndicator && (
            <span className="vat">
               {showVat ? `(${t('incl vat')})` : `(${t('excl vat')})`}
            </span>
         )}
      </PriceCardSectionContainer>
   )
}

export default PriceSection