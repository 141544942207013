import React, { useState, useEffect, useRef } from 'react'
import { styled } from 'linaria/react'
import VbButton from '../../Core/SiteButton'
import AnimateHeight from 'react-animate-height'
import { useContext } from 'react'
import { SiteContext } from '../../Global/SiteContext'
import ProductListCard from '../../Core/Cards/ProductListCard'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import ProductSearchCardHorizontal from '../../Core/Cards/ProductSearchCardHorizontal'
import { theme } from '../../Theme'
import { Cancel } from 'iconoir-react'
import {
   clearInputSearchFieldsById,
   elementsToKeepInArray,
   removeAlternativeString,
} from '../../Core/Util/Helpers'
import { useIntl } from '@jetshop/intl'
import Link from '../../Core/Link'
import LoadingArrowIcon from '../../ui/LoadingArrowIcon'
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings'
import { Product_Page_Settings } from '../../CustomElements/elementTypes'

const SearchResultContainer = styled(AnimateHeight)`
   background-color: var(--color-white);
   font-family: var(--font-industry);
   border-radius: 0px 0px 2rem 2rem;
   width: 100%;
   z-index: 1030;
   position: absolute;

   ${theme.below.xl} {
      /* top: 4.063rem; */
   }

   ${theme.below.lg} {
      /* top: 4.563rem; */
   }

   ${theme.below.md} {
      padding-top: 2rem;
      top: 4rem;
      z-index: 0;
   }

   &.search-result-visible {
      display: block;
   }

   &.search-result-hidden {
      display: none;
   }

   .search-result-title {
      font-size: 1.3rem;
      font-weight: 700;
      margin-bottom: 0;
      font-family: var(--font-industry);
      ${theme.above.md} {
         margin-bottom: 1rem;
      }
   }

   li {
      list-style: none;
   }

   li a {
      color: #000;
      font-weight: 500;
      font-family: var(--font-industry);
   }

   .close-search-icon-container {
      position: relative;
      margin-bottom: 0rem;
      ${theme.above.md} {
         margin-bottom: 0.5rem;
      }
   }

   #close-search-button {
      position: absolute;
      top: 5px;
      left: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
   }

   .search-card-wrapper {
      ${theme.below.lg} {
         height: 28vh;
         overflow: auto;
         margin-bottom: 1rem;
      }

      @media only screen and (max-width: 768px) and (max-height: 1000px) {
         overflow: auto;
         /* height: 39vh; */

         height: clamp(50px, 34vh, 600px);
         margin-bottom: 0.5rem;
         padding-bottom: 0px;
      }
   }

   .search-proposals-wrapper {
      @media only screen and (max-width: 768px) and (max-height: 895px) {
         overflow: auto;
         height: clamp(50px, 22vh, 220px);
      }
      @media only screen and (max-width: 768px) and (min-height: 896px) {
         height: auto;
      }
   }
`

const CancelIcon = styled(Cancel)`
      display: block;
      cursor: pointer;
      position: relative;
      color: var(--color-vb-blue);
      &:hover {
         opacity: 0.5;
      }
`

const SearchResultDesktop = ({
   products,
   categories,
   searchTermSuggestions,
   productHitsBrands,
   metaData,
   loading,
}) => {
   const [getSetting, loadingSiteSettings] = useSiteSettingsQuery()
   const productSettings = getSetting(null, Product_Page_Settings)
   const [height, setHeight] = useState(0)
   const searchResultRef = useRef(null)
   const t = useIntl()

   const {
      cancelCloseSearch,
      debouncedCloseSearch,
      showSearch,
      setShowSearch,
      setShowOverlay,
      performSearch,
      searchValue,
   } = useContext(SiteContext)

   const handleSearchBtnClick = async searchStr => {
      performSearch()
   }

   useEffect(() => {
      const handleClickInDom = event => {
         if (event?.target?.id == 'close-search-button') {
            debouncedCloseSearch()
         }

         if (
            !searchResultRef.current?.contains(event.target) &&
            event?.target?.id != 'searchfield-mobile' &&
            event?.target?.id != 'searchfield-desktop' &&
            event?.target?.id != 'root' &&
            event?.target?.id != 'clear-search'
         ) {
            debouncedCloseSearch()
         }
      }
      setHeight(showSearch ? 'auto' : 0)
      setShowOverlay(showSearch)
      document.addEventListener('click', handleClickInDom)
      document.body.classList.toggle('prevent-body-scroll', showSearch)

      return () => {
         document.removeEventListener('click', handleClickInDom)
         document.body.classList.remove('prevent-body-scroll')
      }
   }, [showSearch, setShowOverlay, debouncedCloseSearch])

   return (
      <>
         <SearchResultContainer
            className={`${
               showSearch ? 'search-result-visible' : 'search-result-hidden'
            }`}
            duration={500}
            height={height}
         >
            <div
               className="px-128 py-64 mb-16"
               ref={searchResultRef}
               onClick={() => cancelCloseSearch()}
            >
               <div className="search-content-wrapper row">
                  <div className="search-proposals-wrapper col-12 col-xl-2 order-xl-1 order-2 pt-3 pt-md-0">
                     <div className="search-proposals-content row">
                        <div className="col-6 col-xl-12 pt-0 pt-md-2">
                           <p className="search-result-title mb-1">
                              {t('Search-result')}
                           </p>
                           <ul className="m-0 p-0">
                              {searchTermSuggestions?.map((sugg, i) => {
                                 if (!sugg?.term) {
                                    return null
                                 }
                                 return (
                                    <li key={i}>
                                       <Link
                                          to={`/search?q=${sugg.term}`}
                                          className="link link--vb"
                                          onNavigation={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false)
                                          }}
                                       >
                                          {sugg.term}
                                       </Link>
                                    </li>
                                 )
                              })}
                           </ul>
                        </div>
                        <div className="col-6 col-xl-12 pt-0 pt-md-2">
                           <p className="search-result-title pt-0 pt-md-2 mb-1">
                              {t('categories')}
                           </p>
                           <ul className="m-0 p-0">
                              {categories?.map((sugg, i) => {
                                 return (
                                    <li key={i}>
                                       <Link
                                          to={sugg?.path}
                                          onNavigation={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false)
                                          }}
                                          className="link link--vb"
                                       >
                                          {removeAlternativeString(sugg?.name)}
                                       </Link>
                                    </li>
                                 )
                              })}
                           </ul>
                        </div>
                        <div className="col-6 col-xl-12 pt-0 pt-md-2">
                           <p className="search-result-title mb-1">
                              {t('brands')}
                           </p>
                           <ul className="m-0 p-0">
                              {productHitsBrands?.map((sugg, i) => {
                                 return (
                                    <li key={i}>
                                       <Link
                                          to={sugg?.path}
                                          onNavigation={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false)
                                          }}
                                          className="link link--vb"
                                       >
                                          {sugg?.name}
                                       </Link>
                                    </li>
                                 )
                              })}
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-xl-10 order-xl-2 order-1">
                     <div className="d-flex justify-content-between align-items-center">
                        <p className="search-result-title">
                           {t('product-result-suggestions')}
                        </p>

                        <div className="close-search-icon-container">
                           <CancelIcon
                              strokeWidth={2.0}
                              width={40}
                              height={40}
                              className="cancel-icon"
                           ></CancelIcon>
                           <div id="close-search-button"></div>
                        </div>
                     </div>

                     {/* Desktop div */}
                     <div className="row pb-0 pb-md-4 d-flex search-card-wrapper">
                        <Above breakpoint="xxxl">
                           <>
                              {products?.map((product, i) => {
                                 return (
                                    <div className="col-2 d-flex" key={i}>
                                       <ProductListCard
                                          product={product}
                                          productSettings={productSettings}
                                          closeModal={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false)
                                          }}
                                       ></ProductListCard>
                                    </div>
                                 )
                              })}
                           </>
                        </Above>
                        <Below breakpoint="xxxl">
                           <Above breakpoint="xxl">
                              <>
                                 {elementsToKeepInArray(products ?? [], 4)?.map(
                                    (product, i) => {
                                       return (
                                          <div className="col-3 d-flex" key={i}>
                                             <ProductListCard
                                                product={product}
                                                productSettings={
                                                   productSettings
                                                }
                                                closeModal={() => {
                                                   clearInputSearchFieldsById()
                                                   setShowSearch(false)
                                                }}
                                             ></ProductListCard>
                                          </div>
                                       )
                                    }
                                 )}
                              </>
                           </Above>
                        </Below>
                        <Below breakpoint="xxl">
                           <>
                              {elementsToKeepInArray(products ?? [], 3)?.map(
                                 (product, i) => {
                                    return (
                                       <div className="col-12" key={i}>
                                          <ProductSearchCardHorizontal
                                             onNavigationClick={() => {
                                                clearInputSearchFieldsById()
                                                setShowSearch(false)
                                             }}
                                             product={product}
                                          ></ProductSearchCardHorizontal>
                                       </div>
                                    )
                                 }
                              )}
                           </>
                        </Below>
                     </div>

                     <div className="d-flex justify-content-center">
                        <VbButton
                           size="medium"
                           styleType="solid"
                           color="blue"
                           disabled={!searchValue}
                           className="w-100 py-8"
                           onClick={() => handleSearchBtnClick()}
                        >
                           {t('show-all-products')}{' '}
                           <LoadingArrowIcon loading={loading} />
                        </VbButton>
                     </div>
                  </div>
               </div>
            </div>
         </SearchResultContainer>
      </>
   )
}

export default SearchResultDesktop
