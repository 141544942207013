import React, { useState } from 'react'
import { styled } from 'linaria/react'
import 'react-modern-drawer/dist/index.css'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import MobileMenu from './MobileMenu'
import MenuButton from '../../Core/Buttons/MenuButton'

const MenuContainer = styled.div`
   background-color: #195178;
   color: #fff;
   transition: all 0.5s ease;

   button:hover {
      opacity: 0.75;
      transition: all 0.5s ease;
   }

   span {
      font-family: var(--font-industry);
      font-weight: 600;
      line-height: initial;
   }

   button {
      display: flex;
      align-items: center;
      background: none;
      color: #fff;
   }

   .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
`

// const LinksContainer = styled.div`
//    a {
//       display: block;
//    }

//    a:nth-child(3n) {
//       margin-bottom: 2rem;
//    }

//    h1 {
//       font-family: 'HKGrotesk' !important;
//       color: black;
//       font-weight: 700;
//       font-size: 28px;
//       width: fit-content;
//    }
// `

const Menu = ({categories, mobileMenuItems}) => {
   const mobileBreakpoint = "xl"
   const [isOpen, setIsOpen] = useState(false)

   const handleMenuClick = () => {
      setIsOpen(!isOpen)
   }

   return (
      <>
         <Below breakpoint="xl">
            <MenuContainer className="col-md-auto col-auto pl-32 menu-button d-flex align-items-center d-flex d-xl-none">
               <MenuButton isOpen={isOpen} onClick={handleMenuClick}></MenuButton>
            </MenuContainer>
         </Below>
         {/* <Above breakpoint={mobileBreakpoint}>
            <VbDrawer isOpen={isOpen} setIsOpen={setIsOpen} title={t('Menu')}>
               <LinksContainer className="mt-32">
                  {
                     desktopMenuItems?.map((item, i) => {
                        const text = getStringByPropertyName(item?.properties, 'text')
                        const link = getStringByPropertyName(item?.properties, 'link')
                        const isHidden = getStringByPropertyName(item?.properties, 'isActive') === 'hidden'

                        if (isHidden) return null

                        return(
                        <Link to={link} key={i} onNavigation={() => setIsOpen(false)}>
                           <h1 className="link link--vb link--black">
                              {text}
                           </h1>
                        </Link>)
                     })
                  }
               </LinksContainer>
            </VbDrawer>
         </Above> */}
         <Below breakpoint={mobileBreakpoint}>
            <MobileMenu
               categories={categories}
               mobileMenuItems={mobileMenuItems} 
               isOpen={isOpen}
               setIsOpen={setIsOpen}
            ></MobileMenu>
         </Below>
      </>
   )
}

export default Menu
