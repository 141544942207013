import { DIGIFI_FILTER_FIELD_NAMES, DIGIFI_FILTERS_ORDER_PRIORITY, DIGIFI_SORT_VALUES } from "./constants"


export const getLangCollectionPrefix = channelId => {
   switch (channelId) {
      case 1:
         return "-sv"
      case 2:
         return "-da"
      case 3:
         return "-no"
      default:
         return "-sv"
   }
}

export const getSearchParamsFromUrl = (
   initialFilters = [],
   immutableFields = [],
   location = {}
) => {
   if (!location?.search) return initialFilters; // Ensure search exists

   const queryParams = new URLSearchParams(location.search);
   const existingFilters = new Map(
      initialFilters.map((filter) => [filter.fieldName, filter])
   );

   queryParams.forEach((value, key) => {
      if (
         immutableFields.includes(key) ||
         key === DIGIFI_FILTER_FIELD_NAMES.PAGE_SIZE ||
         key === DIGIFI_FILTER_FIELD_NAMES.SEARCH_QUERY
      ) return;

      if (key === DIGIFI_FILTER_FIELD_NAMES.PAGE) {
         const pageValue = parseInt(value, 10);
         if (!isNaN(pageValue) && pageValue > 0) { // Only valid pages
            existingFilters.set(DIGIFI_FILTER_FIELD_NAMES.PAGE, {
               fieldName: DIGIFI_FILTER_FIELD_NAMES.PAGE,
               value: pageValue,
            });
         }
         return;
      }

      if (key === DIGIFI_FILTER_FIELD_NAMES.PRICE) {
         try {
            const priceValues = value
               .split(',')
               .map(v => v.trim()) // Remove extra spaces
               .filter(Boolean) // Remove empty values
               .map(Number) // Convert to numbers
               .filter(v => !isNaN(v)); //  Remove NaN values
      
            // Ensure exactly two valid numbers (min & max) exist
            if (priceValues.length === 2) {
               existingFilters.set(DIGIFI_FILTER_FIELD_NAMES.PRICE, {
                  fieldName: key,
                  value: priceValues,
               });
            } else {
               console.warn(`Invalid price format: "${value}"`);
            }
         } catch (e) {
            console.warn('Error processing price range:', value, e);
         }
         return;
      }

      if (key === DIGIFI_FILTER_FIELD_NAMES.SORT_BY) {
         try {
            if (Object.values(DIGIFI_SORT_VALUES).includes(value)) {
               existingFilters.set(key, {
                  fieldName: key,   
                  value,
               });
            }
         } catch (e) {
            console.warn('Invalid sort value:', value);
         }
         return;
      }

      if (key === DIGIFI_FILTER_FIELD_NAMES.BRAND) {
         try {
            if (!value) return; // Early return if value is null/undefined
      
            const parsedValue = value.includes(',') 
               ? value.split(',') 
               : [value]; // Wrap single values in an array
      
            existingFilters.set(key, {
               fieldName: key,
               value: parsedValue,
            });
         } catch (e) {
            console.warn('Invalid brand value:', value, e);
         }
         return;
      }

      if (key === DIGIFI_FILTER_FIELD_NAMES.PREBOOK && value === 'true') {
         existingFilters.set(key, {
            fieldName: key,
            value: true,
         });
         return;
      }
   });

   return Array.from(existingFilters.values())
};


export const syncFiltersWithUrlParams = (
   currentFilters = [],
   isSearchPage = false
) => {
   if (typeof window === "undefined" || !window.location) return;

   const existingParams = new URLSearchParams(window?.location?.search || '');
   const queryParams = new URLSearchParams();

   // Preserve eclub if it exists
   const eclubParam = existingParams.get('eclub');
   if (eclubParam) {
      queryParams.set('eclub', eclubParam);
   }

   // Preserve q if it exists and isSearchPage is true
   const qParam = existingParams.get('q');
   if (qParam && isSearchPage) {
      queryParams.set('q', qParam);
   }

   // Process only filter-related params
   currentFilters.forEach(({ fieldName, value }) => {
      if (
         fieldName === DIGIFI_FILTER_FIELD_NAMES.PAGE_SIZE ||
         fieldName === DIGIFI_FILTER_FIELD_NAMES.SEARCH_QUERY ||
         (fieldName === DIGIFI_FILTER_FIELD_NAMES.PAGE && (value === 1 || value === '1'))
      ) {
         return; // Skip these fields
      }

      if (value) {
         queryParams.set(
            fieldName,
            Array.isArray(value) ? value.join(',') : value
         );
      }
   });

   // Construct new URL with preserved params
   const queryString = queryParams.toString();
   const newUrl = `${window.location.pathname}${queryString ? `?${queryString}` : ''}`;

   //  Update URL only if necessary
   if (newUrl !== window.location.href) {
      window.history.replaceState({}, '', newUrl);
   }
};

/**
 * Sorts filters based on predefined priority.
 * Ensures filters appear in a consistent order.
 * @param {Array} filters - The filters to sort
 * @param {Array} priorityOrder - The priority order of the filters (default: DIGIFI_FILTERS_ORDER_PRIORITY)
 * @returns {Array} - The sorted filters
 */
export const sortFiltersByPriority = (filters, priorityOrder = DIGIFI_FILTERS_ORDER_PRIORITY) => {
   return [...filters].sort((a, b) => {
      const aIndex = priorityOrder.indexOf(a.fieldName);
      const bIndex = priorityOrder.indexOf(b.fieldName);
      return (aIndex !== -1 ? aIndex : priorityOrder.length) -
             (bIndex !== -1 ? bIndex : priorityOrder.length);
   });
};