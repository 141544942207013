import React, { useEffect, useState, useContext, useRef } from 'react'
import { debounce } from 'lodash'
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext'
import { useMutation } from '@apollo/react-hooks'
import { addMultipleToCart } from '../Cart/addMultipleToCart.gql'
import { useCookies } from 'react-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AddToCartNotification from './AddToCartNotification'
import { Breadcrumbs } from '../Core/BreadCrumbs/BreadCrumbs'
import { useHistory } from 'react-router-dom'
import { clearInputSearchFieldsById } from '../Core/Util/Helpers'

export const SiteContext = React.createContext()

export const WithSiteContext = ({ children }) => {
   const cName = 'vb-showVat'
   const [cookies, setCookie, removeCookie] = useCookies([cName])
   const [showVat, setShowVat] = useState(true)
   const [searchValue, setSearchValue] = useState('')
   const searchRedirectPathRef = useRef(undefined);
   const [showSearch, setShowSearch] = useState(false)
   const [breadCrumbData, setBreadCrumbData] = useState(null)

   const [showOverlay, setShowOverlay] = useState(false)

   const { cartId, setCartId } = useContext(CartIdContext)
   const [cartProduct, setCartProduct] = useState({})

   const [activeVariant, setActiveVariant] = useState(null)
   const [productNumber, setActiveProductNumber] = useState(null)
   const [productReviews, setProductReviews] = useState(null)
   const [packageProducts, setPackageProducts] = useState(null)
   const [reviewsAccOpen, setReviewsAccOpen] = useState(false)

   const [productInformation, setProductInformation] = useState(null)

   const history = useHistory()

   const notify = cartData => {
      toast(
         <AddToCartNotification product={cartProduct} cartData={cartData} />,
         {
            autoClose: 2000,
            position: 'top-right',
            progressClassName: 'notification-progress-bar-color',
            hideProgressBar: false,
            closeButton: false,
         }
      )
   }

   const [addMultipleProductsToCartMutation] = useMutation(addMultipleToCart, {
      onCompleted: data => {
         setCartId(data?.addMultipleToCart?.cart?.id)
         notify(data);
      },
   })

   useEffect(() => {
      if (cookies[cName]) {
         setShowVat(!!(cookies[cName] === 'yes'))
      }
   }, [cookies, setShowVat])  

   // useEffect(() => {
   //    // setFormattedProduct(UseFormatProductPage(activeProduct))
   //    console.log(activeProduct)
   //    // setFormattedProduct(UseFormatProductPage(activeProduct))
   // }, [activeProduct])

   const handleShowVatChange = newShowVat => {
      setCookie(cName, newShowVat ? 'yes' : 'no')
      setShowVat(newShowVat)
      const event = new Event('vatChange')
      window.dispatchEvent(event, newShowVat)
   }

   const handleSearchbarChange = value => {
      setSearchValue(value)
      setShowSearch(true)
   }

   const performSearch = (value) => {
      const searchTerm = value || searchValue
      if (!searchTerm) {
         return
      }
      setShowSearch(false)
      history.push(searchRedirectPathRef.current ?? `/search?q=${searchTerm}`)
      clearInputSearchFieldsById()
   }

   const debouncedCloseSearch = debounce(() => {
      setShowSearch(false)
   }, 25)

   const cancelCloseSearch = () => {
      debouncedCloseSearch.cancel()
   }

   const addProductsToCart = (products, notificationData, quantity) => {
      setCartProduct(notificationData)

      const formattedProducts = products.map(product => {
         let temp = { articleNumber: product?.articleNumber, quantity: quantity }
         if(product?.preOrderComments){
            temp.comments = product?.preOrderComments;
         }  
         return temp;
      })

      addMultipleProductsToCartMutation({
         variables: {
            cartId,
            items: formattedProducts
         },
      })
   }

   const GetBreadCrumbs = className => {
      return (
         <Breadcrumbs
            hideLast
            parents={breadCrumbData?.parents}
            title={
               breadCrumbData?.category?.breadcrumbText ||
               breadCrumbData?.category?.name
            }
            className={className}
         />
      )
   }

   const state = {
      showVat,
      handleShowVatChange,
      searchValue,
      searchRedirectPathRef,
      handleSearchbarChange,
      performSearch,
      showSearch,
      setShowSearch,
      debouncedCloseSearch,
      cancelCloseSearch,
      showOverlay,
      setShowOverlay,
      GetBreadCrumbs,
      setBreadCrumbData,
      setActiveVariant,
      productReviews,
      setProductReviews,
      activeVariant,
      setActiveProductNumber,
      productNumber,
      packageProducts,
      setPackageProducts,
      addProductsToCart,
      setReviewsAccOpen,
      reviewsAccOpen,
      productInformation,
      setProductInformation
   }

   return (
      <SiteContext.Provider value={state}>
         <>
            <ToastContainer style={{ width: '25rem' }} />
            {children}
         </>
      </SiteContext.Provider>
   )
}


