import { useContext, useEffect, useState } from 'react'
import { SiteContext } from '../../Global/SiteContext'
import { useDigifiSearchCore } from './Core/useDigifiSearchCore'
import { DIGIFI_SORT_VALUES, DIGIFI_EXCLUDED_PARENT_CATEGORIES } from '../constants'

const getExactMatchPath = (searchValue, catHits) => {
   const lowestLevelCat = catHits?.reduce((prev, current) => {
      if (current?.name?.toUpperCase() === searchValue?.toUpperCase()) {
         return !prev || current?.level < prev?.level ? current : prev
      }
      return prev
   }, null)

   return lowestLevelCat?.path
}

const filterCategories = (categories) => {
   const visibleCategories = categories?.filter(category => !category?.isHidden) || [];
   
   return visibleCategories.reduce((acc, category) => {
      const sameName = visibleCategories.filter(c => 
         c.name?.toUpperCase() === category.name?.toUpperCase()
      );
      
      // If multiple categories have the same name, exclude those with parentId in DIGIFI_EXCLUDED_PARENT_CATEGORIES
      if (sameName.length > 1) {
         const filtered = sameName.filter(c => !DIGIFI_EXCLUDED_PARENT_CATEGORIES.includes(c.parentId));
         // Add filtered categories if we haven't processed this name yet
         if (!acc.some(c => c.name?.toUpperCase() === category.name?.toUpperCase())) {
            acc.push(...filtered);
         }
      } else {
         // If only one category with this name, add it if we haven't already
         if (!acc.some(c => c.name?.toUpperCase() === category.name?.toUpperCase())) {
            acc.push(category);
         }
      }
      return acc;
   }, []);
};

const useDigifiMultiSearch = () => {
   const [products, setProducts] = useState([])
   const [categories, setCategories] = useState([])
   const [searchTermSuggestions, setSearchTermSuggestions] = useState([])
   const [metaData, setMetaData] = useState(null)
   const [productHitsBrands, setProductHitsBrands] = useState([])
   const { searchValue, searchRedirectPathRef } = useContext(SiteContext)
   const { getBrandsByBrandCategoryIds, multiSearch, loading } = useDigifiSearchCore()

   const resetState = () => {
      setProductHitsBrands([])
      setCategories([])
      setProducts([])
      setSearchTermSuggestions([])
      setMetaData(null)
   }

   const handleProductHitsBrands = async (productHits = []) => {
      const uniqueBrands = [...new Set(productHits.map(hit => hit?.brandCategoryId).filter(Boolean))];

      if (uniqueBrands?.length === 0) {
        setProductHitsBrands([]);
        return;
      }

      const resp = await getBrandsByBrandCategoryIds({ brandCategoryIds: uniqueBrands, sortBy: [DIGIFI_SORT_VALUES.POPULARITY_SCORE_DESC] });

      if (!resp || !Array.isArray(resp)) {
        setProductHitsBrands([]);
        return;
      }
    
      const filteredBrands = resp.filter(brandItem => !brandItem?.isHidden);

      setProductHitsBrands(filteredBrands);
    };
    
   useEffect(() => {
      const fetchSearchResults = async () => {
         const resp = await multiSearch({
            searchStr: searchValue ?? '',
            pageSize: 6,
         })

         if (!resp) {
            resetState()
            return
         }

         // Handle the response from the multiSearch
         const productHits = resp?.products
         const categoryHits = filterCategories(resp?.categories)
         const metaData = resp?.metadata
         const searchTerms = resp?.searchTerms

         // Set the exact match redirect path in siteContext
         searchRedirectPathRef.current = getExactMatchPath(
            searchValue,
            categoryHits,
         )
         // Fetch and setProductHitsBrands without awaiting or blocking the main thread
         handleProductHitsBrands(productHits)

         setCategories(categoryHits)
         setProducts(productHits)
         setSearchTermSuggestions(searchTerms)
         setMetaData(metaData)
      }

      fetchSearchResults()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchValue])

   return {
      products,
      categories,
      searchTermSuggestions,
      productHitsBrands,
      metaData,
      loading,
      searchValue
   }
}

export default useDigifiMultiSearch
