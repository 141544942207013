export const BRAND_PAGE_ID = 3833
export const PROJECT_PAGE_ID = 4148
export const GUIDES_PAGE_ID = 4150
export const INSPIRATION_PAGE_ID = 4169
export const BLOG_PAGE_ID = 3820
export const BARGAIN_CORNER_ID = 3100
export const ACTIVATE_ACCOUNT_PAGE_ID = 5103
export const WORK_FOR_US_PAGE_ID = 5124
export const RESTORATION_SHOW_PAGE_ID = 4430

export const STOCK_ID = {
    IN_STOCK: ['1', '2'],
    OUT_OF_STOCK: ['9', '3'],
    PREBOOK: ['14'],
}

export const PREBOOK_SV = "Beräknas åter i lager"; // Swedish
export const PREBOOK_DE = "Beregnet igen på lager"; // Danish
export const PREBOOK_NO = "Beregnet igjen på lager"; // Norwegian
export const PREBOOK_EN = "Expected back in stock"; // English


export const VALID_PREBOOK_NAMES = [
    PREBOOK_SV, 
    PREBOOK_DE, 
    PREBOOK_NO,
    PREBOOK_EN,
];

// Delay values in ms
export const DELAY_MS = {
    DIRECT_SEARCH: 250,
    DEBOUNCE_SEARCH: 220,
}

export const PRODUCT_ADD_TO_CART_MOBILE_ID = "add-to-cart-container-mobile-id"

// UI Constants
export const EXPECTED_PX_HEADER_HEIGHT = {
    xxs: '99px',
    md: '61px',
    lg: '124px'
}

export const MANUAL_TYPE_NAME = {
    SAFETY_SHEET: 'SafetySheet',
    MANUAL: 'Manual',
    DIMENSIONAL_SHEET: 'DimensionalSheet',
}

// Rating threshold for showing test freak rating
export const TEST_FREAK_RATING_THRESHOLD = 3;


export const SHIPPING_ESTIMATE_MESSAGE = {
    WEEKEND_SV: 'Skickas inom 1-3 vardagar!',
    WEEKDAY_SV: 'Skickas inom 24 timmar!',
    WEEKEND_NO: 'Sendes innen 1-3 virkedager!',
    WEEKDAY_NO: 'Sendes innen 24 timer!',
    WEEKEND_DK: 'Afsendes inden for 1-3 hverdage!',
    WEEKDAY_DK: 'Afsendes inden for 24 timer!',
    DEFAULT_MESSAGE_SV: 'Skickas inom 1-3 vardagar!',
    DEFAULT_MESSAGE_NO: 'Sendes innen 1-3 virkedager!',
    DEFAULT_MESSAGE_DK: 'Afsendes inden for 1-3 hverdage!',
};