// Collection base names
export const VECTORY_COLLECTION_BASE = {
   PRODUCTS: 'products',
   CATEGORIES: 'categories',
   BRANDS: 'brands'
}

export const VECTORY_SORT_VALUES = {
   PRICE_INC_VAT_ASC: "priceIncVat:asc",
   PRICE_INC_VAT_DESC: "priceIncVat:desc",
   PRICE_EX_VAT_ASC: "priceExVat:asc",
   PRICE_EX_VAT_DESC: "priceExVat:desc",
   POPULARITY_SCORE_DESC: "popularityScore:desc",
   POPULARITY_SCORE_ASC: "popularityScore:asc",
   IN_STOCK_DESC: "inStock:desc",
   // TEST_FREAK_RATING_ASC: 'testFreakRating:asc',
   // TEST_FREAK_RATING_DESC: 'testFreakRating:desc',
}

export const VECTORY_FILTER_FIELD_NAMES = {
   BRAND_NAME: "brandName",
   PRICE: "price",
   FILTER_CATEGORIES: "filterCategories",
   PREBOOK: "prebook",
   IS_NEW: "isNew",
   IS_CAMPAIGN: "isCampaign",
   PRICE_INC_VAT: "priceIncVat",
   PRICE_EX_VAT: "priceExVat",
   BRAND_CATEGORY_ID: "brandCategoryId",
   ITEM_NOS: "itemNos",
}

// Vectory Filter operators 
export const VECTORY_FILTER_OPERATORS = {
   EQUAL: 0,
   NOT_EQUAL: 1,
   GREATER_THAN: 2,
   GREATER_THAN_OR_EQUAL: 3,
   LESS_THAN: 4,
   LESS_THAN_OR_EQUAL: 5,
   STARTS_WITH: 6,
   CONTAINS: 7,
   NOT_CONTAINS: 8,
   EMPTY: 9,
   IS_NULL: 10,
}

// export const VECTORY_PAYLOAD_KEYS = {
//    COLLECTION: "collection",
//    SEARCH_TERM: "searchTerm",
//    USER_AGENT: "userAgent",
//    PAGE_SIZE: "pageSize",
//    PAGE: "page",
//    FACETS_TO_DISPLAY: "facetsToDisplay",
//    SORT_BY_FIELDS: "sortByFields",
//    DEFAULT_FILTER_CONDITIONS: "defaultFilterConditions",
//    FILTER_CONDITIONS: "filterConditions",
//    FACETING_RULES: "facetingRules",
// }