import { useEffect } from "react";

export default function useScaleFontSizeToParent(ref) {
   useEffect(() => {
      if (!ref.current) return;

      const minSize = 9;
      const maxSize = 13;
      const scaleFactor = 0.06;

      function updateFontSize() {
         if (!ref.current || !ref.current.parentElement) return;

         const parent = ref.current.parentElement;
         const parentWidth = parent.getBoundingClientRect().width;

         if (parentWidth > 0) {
            const newFontSize = Math.min(maxSize, Math.max(minSize, parentWidth * scaleFactor)); // Scale between minSize - maxSize

            // Only update font-size if it actually changes (prevents unnecessary updates)
            if (ref.current.style.fontSize !== `${newFontSize}px`) {
               ref.current.style.fontSize = `${newFontSize}px`;
            }
         }
      }

      // Observe changes in the parent's size, NOT the child elements
      const resizeObserver = new ResizeObserver(updateFontSize);
      if (ref.current?.parentElement) {
         resizeObserver.observe(ref.current.parentElement);
      }

      // Window resize listener
      window.addEventListener("resize", updateFontSize);

      return () => {
         resizeObserver.disconnect();
         window.removeEventListener("resize", updateFontSize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
}