import { useEffect, useState } from 'react'
/**
 * This custom hook returns a boolean indicating whether the window width is
 * larger than 576px.
 * @returns {Boolean} - True if the window width is larger than 576px, otherwise false.
 */
const useIsAboveSm = () => {
   const [matches, setMatches] = useState(false)

   useEffect(() => {
      if (!window || !window.matchMedia) {
         return; // Early return if necessary APIs are not available
      }

      const query = '(min-width: 576px)'
      const media = window.matchMedia(query)

      const handleMediaChange = event => {
         setMatches(event.matches)
      }

      // Initial check
      setMatches(media.matches)

      // Listen for changes using addEventListener
      media.addEventListener('change', handleMediaChange)

      // Cleanup
      return () => media.removeEventListener('change', handleMediaChange)
   }, [])

   return matches
}

/**
 * This custom hook returns a boolean indicating whether the window width is
 * larger than 768px.
 * @returns {Boolean} - True if the window width is larger than 768px, otherwise false.
 */
const useIsAboveMd = () => {
   const [matches, setMatches] = useState(false)

   useEffect(() => {
      if (!window || !window.matchMedia) {
         return; // Early return if necessary APIs are not available
      }

      const query = '(min-width: 768px)'
      const media = window.matchMedia(query)

      const handleMediaChange = event => {
         setMatches(event.matches)
      }

      // Initial check
      setMatches(media.matches)

      // Listen for changes using addEventListener
      media.addEventListener('change', handleMediaChange)

      // Cleanup
      return () => media.removeEventListener('change', handleMediaChange)
   }, [])

   return matches
}

/**
 * This custom hook returns a boolean indicating whether the window width is
 * larger than 992px.
 * @returns {Boolean} - True if the window width is larger than 992px, otherwise false.
 */
const useIsAboveLg = () => {
      // Determine if the viewport width is initially above the lg breakpoint.
   const initialMatch = typeof window !== 'undefined' && window.matchMedia ? window.matchMedia('(min-width: 992px)').matches : false;
   const [matches, setMatches] = useState(initialMatch)

   useEffect(() => {
      if (!window || !window.matchMedia) {
         return; // Early return if necessary APIs are not available
      }

      const query = '(min-width: 992px)'
      const media = window.matchMedia(query)

      const handleMediaChange = event => {
         setMatches(event.matches)
      }

      // Initial check
      setMatches(media.matches)

      // Listen for changes using addEventListener
      media.addEventListener('change', handleMediaChange)

      // Cleanup
      return () => media.removeEventListener('change', handleMediaChange)
   }, [])

   return matches
}

/**
 * This custom hook returns a boolean indicating whether the window width is
 * larger than the provided value.
 * @param {String} abovePxValue - The pixel value to determine if the window width is above a certain value.
 * @returns {Boolean} - True if the window width is larger than the specified value, otherwise false.
 */
const useIsAboveCustomBreakPoint = (abovePxValue) => {
   const [matches, setMatches] = useState(false)

   useEffect(() => {
      if (!window || !window.matchMedia) {
         return; // Early return if necessary APIs are not available
      }

      const media = window.matchMedia(`(min-width: ${abovePxValue}px)`)

      const handleMediaChange = event => {
         setMatches(event.matches)
      }

      // Initial check
      setMatches(media.matches)

      // Listen for changes using addEventListener
      media.addEventListener('change', handleMediaChange)

      // Cleanup
      return () => media.removeEventListener('change', handleMediaChange)
   }, [abovePxValue])

   return matches
}

export { useIsAboveSm, useIsAboveMd, useIsAboveLg, useIsAboveCustomBreakPoint };