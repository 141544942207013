import React from "react";
import PriceSection from "./PriceSection";
import { styled } from "linaria/react";

const PriceSectionWithLabelContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   column-gap: 0.4rem;
   align-items: baseline;
   .price-section-with-label-text {
      font-size: 1rem;
      opacity: 0.8;
      font-weight: 300;
      font-weight: 500;
      font-family: var(--font-industry);
   }
`;

const PriceSectionWithLabel = ({ label, product, selectedChannel }) => {
   return (
      <PriceSectionWithLabelContainer className="d-flex flex-wrap">
         {label && <span className="price-section-with-label-text">{label}</span>}
         <PriceSection
            overrideWrapperClass="pe-0"
            product={product}
            selectedChannel={selectedChannel}
         />
      </PriceSectionWithLabelContainer>
   );
};

export default PriceSectionWithLabel;