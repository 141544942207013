export const DIGIFI_SORT_VALUES = {
   RELEVANCE: '',
   PRICE_ASC: 'price:asc',
   PRICE_DESC: 'price:desc',
   RATING_ASC: 'testFreakRating:asc',
   RATING_DESC: 'testFreakRating:desc',
   POPULARITY_SCORE_DESC: 'popularityScore:desc',
   POPULARITY_SCORE_ASC: 'popularityScore:asc',
}

export const DIGIFI_FILTER_FIELD_NAMES = {
   BRAND_CATEGORY_ID: "brandCategoryId",
   BRAND: "brand",
   SORT_BY: "sortBy",
   PRICE: "price",
   SEARCH_QUERY: "searchQuery",
   PAGE: "page",
   PAGE_SIZE: "pageSize",
   FILTER_CATEGORIES: "filterCategories",
   PREBOOK: "prebook",
   IS_NEW: "isNew",
   IS_CAMPAIGN: "isCampaign",
}

export const DIGIFI_FILTERS_ORDER_PRIORITY = [
   DIGIFI_FILTER_FIELD_NAMES.PREBOOK,
   DIGIFI_FILTER_FIELD_NAMES.PRICE,
   DIGIFI_FILTER_FIELD_NAMES.SORT_BY,
   DIGIFI_FILTER_FIELD_NAMES.BRAND,
];

export const DIGIFI_DEFAULT_PAGE_SIZE = 96
export const DIGIFI_DEFAULT_PAGE = 1

export const DIGIFI_EXCLUDED_PARENT_CATEGORIES = ["3938", "4089", "4426", "3834","3898", "3919","3926","4088"];