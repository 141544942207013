import React, { useContext, useRef } from 'react'
import { styled } from 'linaria/react'
import UseSearchLogic from '../../Core/Hooks/UseSearchLogic'
import { useIntl } from '@jetshop/intl'
import { useHeaderContext } from './HeaderContext/HeaderContext'
import { SiteContext } from '../../Global/SiteContext'
import { ArrowLeft, Search } from 'iconoir-react'
import Button from '../../Core/SiteButton'
import ClearSearch from '../../Core/Icons/ClearSearch'

const SearchMobile = styled.div`
   background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(25, 81, 120, 1) 50%,
      rgba(25, 81, 120, 1) 100%
   );
   margin-top: -8px;
   position: relative;

   .search-mobile {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-family: var(--font-industry);
      font-size: 1rem;
      line-height: normal;
      border-radius: 50px;
      scale: 1;
      transition: all 0.2s ease;
      font-weight: 500;
      border: 8px solid var(--color-vb-blue) !important;
      padding: ${props =>
         props.isScrolled
            ? '0.5rem 2.8rem 0.5rem 2.8rem'
            : '0.8rem 2.8rem 0.8rem 2.8rem'};
      padding-right: 85px;
   }

   .search-mobile:focus {
      box-shadow: none;
   }

   .icons-container {
      transition: margin 0.2s ease;
      margin-top: ${props => (props.isScrolled ? '-4px' : '2px')};
      position: absolute;
      padding: 1rem 0rem 0.5rem 0rem !important;
      margin-left: 1.4rem;
      top: 0;
      left: 0;
   }

   .spinner-wrapper {
      transition: margin 0.2s ease;
      margin-top: ${props => (props.isScrolled ? '-1px' : '4px')};
      position: absolute;
      padding: 0.8rem 0rem;
      margin-right: 1rem;
      top: 0;
      right: 0;
      opacity: 0.5;
   }

   input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
   }

   .clear-search-container {
      position: absolute;
      right: 70px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
   }
`

const PerformSearchButton = styled(Button)`
   right: 5px;
   width: 50px;
   height: calc(100% - 23px);
   background: var(--color-vb-blue);
   border: 2px solid var(--color-vb-blue);
   border-radius: 50px;
   padding: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
   position: absolute;
   right: 11px;
   top: 50%;
   transform: translateY(-50%);
   z-index: 2;
   &:hover svg {
      transform: translate(0.1rem, 0);
   }
`

const ArrowLeftIcon = styled(ArrowLeft)`
   cursor: pointer;
   color: var(--color-vb-blue);
   opacity: 1;
   &:hover {
      opacity: 1;
   }
`

const SearchIcon = styled(Search)`
   opacity: 0.5;
`

function MobileSearchBar() {
   const inputRef = useRef(null)
   const {
      handleInputChange,
      handleInputFocus,
      handleKeyPressed,
      handleClear,
   } = UseSearchLogic(inputRef)
   const t = useIntl()
   const { isScrolled } = useHeaderContext()
   const {
      cancelCloseSearch,
      debouncedCloseSearch,
      showSearch,
      setShowSearch,
      setShowOverlay,
      performSearch,
   } = useContext(SiteContext)

   const handleSearchBtnClick = () => {
      performSearch()
   }

   return (
      <SearchMobile
         className="form-group d-block d-md-none"
         isScrolled={isScrolled}
      >
         <input
            ref={inputRef}
            type="search"
            id="searchfield-mobile"
            className="form-control search-mobile"
            placeholder={t('Searchfield-placeholder')}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyPressed}
         />

         <div className="icons-container pr-8">
            {showSearch ? (
               <ArrowLeftIcon
                  strokeWidth={3.0}
                  onClick={() => setShowSearch(false)}
               />
            ) : (
               <SearchIcon strokeWidth={2.0} />
            )}
         </div>

         <div className="clear-search-container">
            <ClearSearch inputRef={inputRef} handleClear={handleClear} />
         </div>
         <PerformSearchButton
            styleType="solid"
            color="blue"
            onClick={() => handleSearchBtnClick(inputRef.current?.value)}
         >
            <Search strokeWidth={2} />
         </PerformSearchButton>
      </SearchMobile>
   )
}

export default MobileSearchBar
