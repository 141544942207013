import React from 'react'
import useDigifiMultiSearch from '../../DigifiSearch/Hooks/useDigifiMultiSearch'
import { useIsAboveCustomBreakPoint } from '../../Core/Hooks/mediaQueryHooks'
import SearchResultDesktop from './SearchResultDesktop'
import SearchResultMobile from './SearchResultMobile'

export default function SearchResult() {
   const {
      products,
      categories,
      searchTermSuggestions,
      productHitsBrands,
      metaData,
      loading,
   } = useDigifiMultiSearch()

   const isAboveXXL = useIsAboveCustomBreakPoint('1400')

   const searchProps = {
      products,
      categories,
      searchTermSuggestions,
      productHitsBrands,
      metaData,
      loading,
   }

   return (
      <>
         {isAboveXXL ? (
            <SearchResultDesktop {...searchProps} />
         ) : (
            <SearchResultMobile {...searchProps} />
         )}
      </>
   )
}
