import { useContext } from 'react'
import packageProductQuery from '../components/ProductPage/Queries/PackageProductQuery.gql'
import { useLazyQuery } from '@apollo/react-hooks'
import { SiteContext } from '../components/Global/SiteContext'
import { STOCK_ID } from '../components/Core/Util/Constants'
import { usePrebookLogic } from './usePrebookLogic'

export const useAddToCart = () => {
   const { addProductsToCart } = useContext(SiteContext)
   const { checkIfPrebookCommentShouldBeAdded } = usePrebookLogic();

   const [getPackage] = useLazyQuery(packageProductQuery, {
      fetchPolicy: 'no-cache',
      onCompleted: async (data) => {
         if (!data) {
            return
         }
         const packageProducts = await Promise.all((data?.product?.package?.items || []).map(async (item) => {
            let packageItem = {
                ...item.product
            };
            await checkIfPrebookCommentShouldBeAdded(packageItem);
            return packageItem;
         }));
         const notificationData = getNotificationData(data, packageProducts)
         addProductsToCart(packageProducts ?? [], notificationData, 1)
      },
   })

   const addProductToCart = (product, articleNumber, quantity, isPackage) => {
      if (isPackage) {
         getPackage({ variables: { articleNumber: articleNumber } })
         return
      }

      //Add articleNumber to product if it doesn't exist
      if (!product?.hasOwnProperty('articleNumber')) {
         if (product?.itemNo) {
            product.articleNumber = product.itemNo;
         } else {
            product.articleNumber = product?.productNumber ? product?.productNumber : product?.sku[0];
         }
      }

      checkIfPrebookCommentShouldBeAdded(product)
      .then(() => {
          addProductsToCart([product], product, quantity)
      })
      .catch(error => {
          console.error('Prebook check failed:', error);
      });
   }

   const getNotificationData = (mainProduct, packageItems) => {
      const products = packageItems ?? [mainProduct]
      const stockStatus = getValidStockStatus(products)

      let displayProduct = { ...mainProduct }
      if(packageItems.length > 0){
         displayProduct = { ...mainProduct.product }
      }
      displayProduct.stockStatus = stockStatus
      return displayProduct
   }

   const getValidStockStatus = (products) => {
      let stockStatus = null
      for (const product of products) {
         const stockStatusId = product?.stockStatus?.stockStatusId
         if (STOCK_ID.IN_STOCK.includes(stockStatusId)) {
            stockStatus = product?.stockStatus
         } else if (STOCK_ID.PREBOOK.includes(stockStatusId)) {
            stockStatus = product?.stockStatus
            return stockStatus
         }
      }
      return stockStatus
   }

   return { addProductToCart }
}