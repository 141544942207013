import React, { useContext, useRef } from 'react'
import { styled } from 'linaria/react'
import UseSearchLogic from '../../Core/Hooks/UseSearchLogic'
import { useIntl } from '@jetshop/intl'
import { ArrowLeft, Search } from 'iconoir-react'
import ClearSearch from '../../Core/Icons/ClearSearch'
import Button from '../../Core/SiteButton'
import { SiteContext } from '../../Global/SiteContext'

const SearchGroup = styled.div`
   position: relative;
   min-width: 305px;

   .search {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-family: var(--font-industry);
      font-size: 1rem;
      line-height: normal;
      padding: 0.8rem 3.2rem;
      padding-right: 80px;
      border: 0;
      font-weight: 500;
      border-radius: 50px;
      scale: 1;
      transition: all 0.5s ease;
      font-weight: 500;
   }

   .search:hover {
      box-shadow: 0 0 0.5rem 3px rgba(255, 255, 255, 0.2);
   }

   .search:placeholder {
      font-family: var(--font-grotesk);
   }

   .search:focus {
      box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.15);
      transition: scale 0.5s ease, box-shadow 0.5s ease;
   }

   .icon {
      position: absolute;
      padding: 0.75rem 0rem;
      margin-left: 0.7rem;
      top: 0;
      left: 0;
      opacity: 0.5;
   }

   .icons-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1rem;
      display: flex;
      align-items: center;
   }

   .spinner-wrapper {
      transition: margin 0.2s ease;
      margin-top: ${props => (props.isScrolled ? '-1px' : '4px')};
      position: absolute;
      padding: 0.8rem 0rem;
      margin-right: 1rem;
      top: 0;
      right: 0;
      opacity: 0.5;
   }

   input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
   }

   .clear-search-container {
      position: absolute;
      right: 60px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
   }
`

const PerformSearchButton = styled(Button)`
   width: 50px;
   height: calc(100% - 7px);
   background: var(--color-vb-blue);
   border: 2px solid var(--color-vb-blue);
   border-radius: 50px;
   padding: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
   position: absolute;
   right: 3px;
   top: 50%;
   transform: translateY(-50%);
   z-index: 2;
   &:hover svg {
      transform: translate(0.1rem, 0);
   }
`

const ArrowLeftIcon = styled(ArrowLeft)`
   cursor: pointer;
   color: var(--color-vb-blue);
   opacity: 1;
   &:hover {
      opacity: 1;
   }
`

const SearchIcon = styled(Search)`
   opacity: 0.5;
`

const SearchBar = () => {
   const inputRef = useRef(null)
   const {
      handleInputChange,
      handleInputFocus,
      handleKeyPressed,
      handleClear,
   } = UseSearchLogic(inputRef)
   const t = useIntl()
   const {
      cancelCloseSearch,
      debouncedCloseSearch,
      showSearch,
      setShowSearch,
      setShowOverlay,
      performSearch,
      searchValue,
   } = useContext(SiteContext)

   const handleSearchBtnClick = () => {
      performSearch()
   }

   return (
      <>
         <SearchGroup className="form-group">
            <input
               ref={inputRef}
               type="search"
               id="searchfield-desktop"
               className="form-control search"
               placeholder={t('Searchfield-placeholder')}
               onChange={handleInputChange}
               onFocus={handleInputFocus}
               onKeyUp={handleKeyPressed}
            />

            <div className="icons-container pr-8">
               {showSearch ? (
                  <ArrowLeftIcon
                     strokeWidth={3.0}
                     onClick={() => setShowSearch(false)}
                  />
               ) : (
                  <SearchIcon strokeWidth={2.0} />
               )}
            </div>

            <div className="clear-search-container">
               <ClearSearch inputRef={inputRef} handleClear={handleClear} />
            </div>
            <PerformSearchButton
               styleType="solid"
               color="blue"
               onClick={handleSearchBtnClick}
            >
               <Search strokeWidth={2} />
            </PerformSearchButton>
         </SearchGroup>
      </>
   )
}

export default SearchBar
