import { useContext, useState } from 'react'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { ensureSearchProductsResponse, getEmptySearchProductsResponse, vectoryMapping } from '../../Formatters/Contracts/NormalizeVectoryData'
import { vectoryService } from '../../../Vectory/Services/VectoryService'
import { getLangCollectionPrefix } from '../../Helpers'
import { VECTORY_COLLECTION_BASE } from '../../../Vectory/Constants'
import { isProductCodeType } from '../../../Core/Util/Helpers'
import { DIGIFI_SORT_VALUES } from '../../constants'

export const useDigifiSearchCore = () => {
   const channelId = useContext(ChannelContext)?.selectedChannel?.id
   const [loading, setLoading] = useState(false)
   const [errorMessage, setErrorMessage] = useState(null)

   const getProductByItemNo = async ({ itemNo }) => {
      try {
         setLoading(true)
         const response = await vectoryService.getProductByItemNo({
            itemNo,
            collection: `${
               VECTORY_COLLECTION_BASE.PRODUCTS
            }${getLangCollectionPrefix(channelId)}`,
         })
         return vectoryMapping.ensureProductStructure(
            response.results[0]?.payload
         )
      } catch (error) {
         setErrorMessage(error?.message || 'An error occurred')
         return null
      } finally {
         setLoading(false)
      }
   }

   const getProductsByItemNos = async ({ itemNos, pageSize, disableSorting }) => {
      try {
         setLoading(true)
         const response = await vectoryService.getProductsByItemNos({
            itemNos,
            collection: `${
               VECTORY_COLLECTION_BASE.PRODUCTS
            }${getLangCollectionPrefix(channelId)}`,
            pageSize,
            disableSorting
         })
         return (
            response?.results?.map(hit =>
               vectoryMapping.ensureProductStructure(hit?.payload)
            ) ?? []
         )
      } catch (error) {
         setErrorMessage(error?.message || 'An error occurred')
         return null
      } finally {
         setLoading(false)
      }
   }

   const getCategoryByCatId = async ({ categoryId }) => {
      try {
         setLoading(true)
         const response = await vectoryService.getCategoryByCatId({
            categoryId,
            collection: `${
               VECTORY_COLLECTION_BASE.CATEGORIES
            }${getLangCollectionPrefix(channelId)}`,
         })
         return vectoryMapping.ensureCategoryStructure(
            response.results[0]?.payload
         )
      } catch (error) {
         setErrorMessage(error?.message || 'An error occurred')
         return null
      } finally {
         setLoading(false)
      }
   }

   const getBrandsByNames = async ({ brandNames }) => {
      try {
         setLoading(true)
         const vectoryResponse = await vectoryService.getBrandsByNames({
            brandNames,
            collection: `${
               VECTORY_COLLECTION_BASE.BRANDS
            }${getLangCollectionPrefix(channelId)}`,
         })

         return (
            vectoryResponse?.results?.map(hit =>
               vectoryMapping.ensureBrandStructure(hit?.payload)
            ) ?? []
         )
      } catch (error) {
         return null
      } finally {
         setLoading(false)
      }
   }

   const getBrandsByBrandCategoryIds = async ({ brandCategoryIds, sortBy }) => {
      try {
         setLoading(true)
         const vectoryResponse =
            await vectoryService.getBrandsByBrandCategoryIds({
               brandCategoryIds,
               collection: `${
                  VECTORY_COLLECTION_BASE.BRANDS
               }${getLangCollectionPrefix(channelId)}`,
               sortBy,
            })

         return (
            vectoryResponse?.results?.map(hit =>
               vectoryMapping.ensureBrandStructure(hit?.payload)
            ) ?? []
         )
      } catch (error) {
         return null
      } finally {
         setLoading(false)
      }
   }

   const multiSearch = async ({ searchStr, pageSize = 6 }) => {
      try {
         setLoading(true)

         const collectionPrefix = getLangCollectionPrefix(channelId)

         const processResults = (result, mappingFn) => {
            if (result.status === 'fulfilled') {
               const results =
                  result.value?.results?.map(hit => mappingFn(hit?.payload)) ??
                  []
               return results
            }
            return []
         }
         const isPossibleArticleNumber = isProductCodeType("ARTICLE_NUMBER", searchStr)
         const overrideProductsDefaultSortByFields = isProductCodeType("HAS_AT_LEAST_13_DIGITS", searchStr)

         const [
            productsResult,
            categoriesResult, 
            searchTermsResult,
         ] = await Promise.allSettled([
         Promise.all([
            isPossibleArticleNumber
               ? vectoryService.getProductByVariantNo({
                    itemNo: searchStr,
                    collection: `${VECTORY_COLLECTION_BASE.PRODUCTS}${collectionPrefix}`,
                 })
               : Promise.resolve({ results: [] }),

            vectoryService.getProductsBySearchTerm({
               filters: [],
               collection: `${VECTORY_COLLECTION_BASE.PRODUCTS}${collectionPrefix}`,
               defaultFilters: [],
               searchTerm: searchStr,
               pageSize: 6,
               overrideDefaultSortByFields: overrideProductsDefaultSortByFields,
               ...(overrideProductsDefaultSortByFields && { sortBy: [] })
            })
         ])
         .then(([variantResults, searchResults]) => {
            const seen = new Set();
            const combined = [];
        
            // Always add variant result first (only 1 item or empty)
            const variantItem = variantResults.results?.[0]; // Always at most 1 item
            if (variantItem) {
                const itemNo = variantItem?.payload?.itemNo;
                if (itemNo && !seen.has(itemNo)) {
                    seen.add(itemNo);
                    combined.push(variantItem);
                }
            }
        
            // Add search results (up to 6 total)
            for (const result of searchResults.results ?? []) {
                if (combined.length >= 6) break; // Stop once we reach 6 items
                const itemNo = result?.payload?.itemNo;
                if (itemNo && !seen.has(itemNo)) {
                    seen.add(itemNo);
                    combined.push(result);
                }
            }
        
            return { results: combined };
        }),
            vectoryService.getCategoriesBySearchTerm({
               collection: `${VECTORY_COLLECTION_BASE.CATEGORIES}${collectionPrefix}`,
               searchTerm: searchStr,
               pageSize,
               sortBy: [DIGIFI_SORT_VALUES.POPULARITY_SCORE_DESC],
            }),
            vectoryService.getSearchTerms({
               schemaName: `${VECTORY_COLLECTION_BASE.PRODUCTS}${collectionPrefix}`,
               searchTerm: searchStr,
               maxResults: pageSize,
            }),
         ])

         let categories = []
         const categoryIds = ["3991", "4819", "3834", "1274", "1043", "1496", "1966", "1521"]
         if (!searchStr){
            const defaultCategories = await vectoryService.getCategoryByCatIds({categoryIds: categoryIds, collection: `${VECTORY_COLLECTION_BASE.CATEGORIES}${collectionPrefix}`})
            categories = defaultCategories.results
               .map(hit => hit?.payload)
               .sort((a, b) => categoryIds.indexOf(a.id) - categoryIds.indexOf(b.id))
         } else {
            categories = processResults(
               categoriesResult,
               vectoryMapping.ensureCategoryStructure
            )
         }

         const products = processResults(
            productsResult,
            vectoryMapping.ensureProductStructure
         )

         const searchTerms =
            searchTermsResult?.value?.map(hit =>
               vectoryMapping.ensureSearchTermStructure(hit)
            ) ?? []

         const errors = []
         if (productsResult.status === 'rejected')
            errors.push('Failed to fetch products')
         if (categoriesResult.status === 'rejected')
            errors.push('Failed to fetch categories')
         if (searchTermsResult.status === 'rejected')
            errors.push('Failed to fetch search terms')
         if (errors.length) {
            console.warn(errors.join('; ') + ' during multiSearch')
         }

         return {
            products,
            categories,
            searchTerms,
            metadata: {
               searchTerm: searchStr,
               totalProducts: products?.length ?? 0,
               totalCategories: categories?.length ?? 0,
            },
         }
      } catch (error) {
         setErrorMessage(
            error?.message || 'An error occurred during multiSearch'
         )
         return null
      } finally {
         setLoading(false)
      }
   }

   const searchProducts = async ({
      filters,
      defaultFilters,
      defaultSearchQuery,
      showVat,
   }) => {
      try {
         setLoading(true)
         setErrorMessage(null)

         const vectoryResponse = await vectoryService.getProductsByFilters({
            filters,
            collection: `${
               VECTORY_COLLECTION_BASE.PRODUCTS
            }${getLangCollectionPrefix(channelId)}`,
            defaultFilters,
            defaultSearchQuery,
            showVat,
         })

         return ensureSearchProductsResponse(vectoryResponse)
      } catch (error) {
         setErrorMessage(error?.message || 'An error occurred')
         return getEmptySearchProductsResponse()
      } finally {
         setLoading(false)
      }
   }

   const getSearchTerms = async ({ searchTerm, maxResults = 6 }) => {
      if (!searchTerm) {
         return null
      }
      try {
         setLoading(true)
         const response = await vectoryService.getSearchTerms({
            searchTerm,
            maxResults,
         })
         return response
      } catch (error) {
         setErrorMessage(error?.message || 'An error occurred')
         return null
      } finally {
         setLoading(false)
      }
   }

   return {
      getProductByItemNo,
      getProductsByItemNos,
      getCategoryByCatId,
      getBrandsByNames,
      getBrandsByBrandCategoryIds,
      multiSearch,
      searchProducts,
      getSearchTerms,
      loading,
      errorMessage,
   }
}
