import React, { useState, useEffect, useRef } from 'react'
import { styled } from 'linaria/react'
import AnimateHeight from 'react-animate-height'
import { useContext } from 'react'
import { SiteContext } from '../../Global/SiteContext'
import { theme } from '../../Theme'
import { Cancel } from 'iconoir-react'
import {
   clearInputSearchFieldsById,
   elementsToKeepInArray,
   removeAlternativeString,
} from '../../Core/Util/Helpers'
import { useIntl } from '@jetshop/intl'
import Link from '../../Core/Link'
import ProductSearchCardHorizontalSmall from '../../Core/Cards/ProductSearchCardHorizontalSmall'
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings'
import { Product_Page_Settings } from '../../CustomElements/elementTypes'

const SearchResultContainer = styled(AnimateHeight)`
   background-color: var(--color-white);
   border-radius: 0px 0px 2rem 2rem;
   width: 100%;
   z-index: 0;
   position: absolute;
   top: 2rem;

   ${theme.above.md} {
      top: unset;
      z-index: 1030;
   }

   &.search-result-visible {
      display: block;
   }

   &.search-result-hidden {
      display: none;
   }

   .search-result-title {
      font-size: 1.2rem;
      font-weight: 700;
      font-family: var(--font-industry);
      margin-bottom: 0.5rem;
   }

   li {
      list-style: none;
   }

   li a {
      font-family: var(--font-industry);
      color: #000;
      font-weight: 500;
   }

   .search-content-wrapper {
      overflow: auto;
      padding-top: 5rem;
      padding-bottom: 6rem;
      align-content: flex-start;
      display: flex;
      height: calc(100vh - 2rem);
      ${theme.above.md} {
         padding-top: 3rem;
         padding-bottom: 6rem;
      }
      ${theme.above.lg} {
         padding-top: 3rem;
         padding-bottom: 6rem;
         height: auto;
      }
   }

   .search-proposals-wrapper {
   }

   .search-proposals-content {
      height: auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1rem;
   }

   .search-card-wrapper {
      height: auto;
      ${theme.below.lg} {
         margin-bottom: 1rem;
      }
   }

   .search-card-container {
      border-bottom: 1px solid var(--color-separator);
      &:first-child {
         border-top: 1px solid var(--color-separator);
      }
   }
`

const CancelIcon = styled(Cancel)`
   display: none;
   ${theme.above.md} {
      display: block;
      cursor: pointer;
      position: relative;
      top: -0.25rem;
      color: var(--color-vb-blue);
      &:hover {
         opacity: 0.5;
      }
   }
`

const SearchResultMobile = ({
   products,
   categories,
   searchTermSuggestions,
   productHitsBrands,
   metaData,
   loading,
}) => {
   const [getSetting, loadingSiteSettings] = useSiteSettingsQuery()
   const productSettings = getSetting(null, Product_Page_Settings)
   const [height, setHeight] = useState(0)
   const searchResultRef = useRef(null)
   const t = useIntl()

   const {
      cancelCloseSearch,
      debouncedCloseSearch,
      showSearch,
      setShowSearch,
      setShowOverlay,
   } = useContext(SiteContext)

   useEffect(() => {
      const handleClickInDom = event => {
         const target = event.target.closest('#close-search-button')
         if (target) {
            debouncedCloseSearch()
         }

         if (
            !searchResultRef.current?.contains(event.target) &&
            event?.target?.id != 'searchfield-mobile' &&
            event?.target?.id != 'searchfield-desktop' &&
            event?.target?.id != 'root' &&
            event?.target?.id != 'clear-search'
         ) {
            debouncedCloseSearch()
         }
      }

      const handleResize = () => {
         const fullHeight =
            typeof window !== 'undefined' ? window.innerHeight : 'auto'
         const isAboveLg =
            typeof window !== 'undefined' ? window.innerWidth >= 996 : false
         setHeight(showSearch ? (isAboveLg ? 'auto' : fullHeight) : 0)
      }

      handleResize()

      setShowOverlay(showSearch)
      document.addEventListener('click', handleClickInDom)
      window.addEventListener('resize', handleResize)
      document.body.classList.toggle('prevent-body-scroll', showSearch)

      return () => {
         document.removeEventListener('click', handleClickInDom)
         window.removeEventListener('resize', handleResize)
         document.body.classList.remove('prevent-body-scroll')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showSearch, setShowOverlay, debouncedCloseSearch])

   return (
      <>
         <SearchResultContainer
            className={`${
               showSearch ? 'search-result-visible' : 'search-result-hidden'
            }`}
            duration={500}
            height={height}
         >
            <div
               className="search-content-wrapper row g-0 px-128"
               ref={searchResultRef}
               onClick={() => cancelCloseSearch()}
            >
               <div className="col-12 col-lg-9 order-1 order-lg-2 pt-2">
                  <div className="d-flex justify-content-between align-items-center">
                     <p className="search-result-title">
                        {t('product-result-suggestions')}
                     </p>
                     <div
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           height: '1px',
                        }}
                     >
                        <CancelIcon
                           strokeWidth={2.0}
                           width={40}
                           height={40}
                           id="close-search-button"
                        ></CancelIcon>
                     </div>
                  </div>
                  <div className="row pb-0 pb-md-4 d-flex search-card-wrapper">
                     {elementsToKeepInArray(products ?? [], 5)?.map(
                        (product, i) => {
                           return (
                              <div
                                 className="search-card-container col-12 "
                                 key={i}
                              >
                                 <ProductSearchCardHorizontalSmall
                                    onNavigationClick={() => {
                                       clearInputSearchFieldsById()
                                       setShowSearch(false)
                                    }}
                                    product={product}
                                    productSettings={productSettings}
                                 ></ProductSearchCardHorizontalSmall>
                              </div>
                           )
                        }
                     )}
                  </div>
               </div>

               <div className="search-proposals-wrapper pt-md-0 col-12 col-lg-3 order-2 order-lg-1">
                  <div className="search-proposals-content">
                     <div className="pt-0 pt-md-2">
                        <p className="search-result-title mb-1">
                           {t('Search-result')}
                        </p>
                        <ul className="m-0 p-0">
                           {searchTermSuggestions?.map((sugg, i) => {
                              if (!sugg?.term) {
                                 return null
                              }
                              return (
                                 <li key={i}>
                                    <Link
                                       to={`/search?q=${sugg.term}`}
                                       className="link link--vb"
                                       onNavigation={() => {
                                          clearInputSearchFieldsById()
                                          setShowSearch(false)
                                       }}
                                    >
                                       {sugg.term}
                                    </Link>
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                     <div className="pt-0 pt-md-2">
                        <p className="search-result-title mb-1">
                           {t('categories')}
                        </p>
                        <ul className="m-0 p-0">
                           {categories?.map((sugg, i) => {
                              return (
                                 <li key={i}>
                                    <Link
                                       to={sugg?.path}
                                       onNavigation={() => {
                                          clearInputSearchFieldsById()
                                          setShowSearch(false)
                                       }}
                                       className="link link--vb"
                                    >
                                       {removeAlternativeString(sugg?.name)}
                                    </Link>
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                     <div className="pt-0 pt-md-2">
                        <p className="search-result-title mb-1">
                           {t('brands')}
                        </p>
                        <ul className="m-0 p-0">
                           {productHitsBrands?.map((sugg, i) => {
                              return (
                                 <li key={i}>
                                    <Link
                                       to={sugg?.path}
                                       onNavigation={() => {
                                          clearInputSearchFieldsById()
                                          setShowSearch(false)
                                       }}
                                       className="link link--vb"
                                    >
                                       {sugg?.name}
                                    </Link>
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </SearchResultContainer>
      </>
   )
}

export default SearchResultMobile
