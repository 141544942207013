import React from 'react'
import { generateUniqueId } from '../Util/Helpers'

const StarIconScalable = ({ filled, size = 80 }) => {
   const gradientId = generateUniqueId();
   const starsId = generateUniqueId();

   const aspectRatio = 75.356 / 13.253; // Calculating aspect ratio from original viewBox
   const height = size / aspectRatio;   // Adjust height to match the given width


   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width={size}            // Dynamically adjust width
         height={height} 
         viewBox="0 0 75.356 13.253"
         style={{ maxWidth: '100%', height: 'auto' }} // Ensure it resizes properly
      >
         <defs>
            <clipPath id={`clip-path-${starsId}`}>
               <path
                  id="stars"
                  d="M4.6,4,6.409.351a.636.636,0,0,1,1.137,0L9.359,4l4.053.589a.629.629,0,0,1,.351,1.076L10.831,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9-3.625,1.9a.634.634,0,0,1-.92-.665L3.125,8.5.192,5.666A.629.629,0,0,1,.543,4.591Zm15.35,0L21.759.351a.636.636,0,0,1,1.137,0L24.709,4l4.053.589a.629.629,0,0,1,.351,1.076L26.181,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9L18.7,13.18a.634.634,0,0,1-.92-.665L18.475,8.5,15.542,5.666a.629.629,0,0,1,.351-1.075ZM35.3,4,37.109.351a.636.636,0,0,1,1.137,0L40.059,4l4.053.589a.629.629,0,0,1,.351,1.076L41.531,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9-3.625,1.9a.634.634,0,0,1-.92-.665L33.825,8.5,30.892,5.666a.629.629,0,0,1,.351-1.075Zm15.35,0L52.459.351a.636.636,0,0,1,1.137,0L55.409,4l4.053.589a.629.629,0,0,1,.351,1.076L56.881,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9L49.4,13.18a.634.634,0,0,1-.92-.665L49.175,8.5,46.242,5.666a.629.629,0,0,1,.351-1.075ZM66,4,67.809.351a.636.636,0,0,1,1.137,0L70.759,4l4.053.589a.629.629,0,0,1,.351,1.076L72.231,8.5l.692,4.012A.634.634,0,0,1,72,13.18l-3.624-1.9-3.625,1.9a.634.634,0,0,1-.92-.665L64.525,8.5,61.592,5.666a.629.629,0,0,1,.351-1.075Z"
                  fill="#1a1818"
               />
            </clipPath>
            <linearGradient
               id={`linear-gradient-${gradientId}`}
               y1="1"
               x2="1"
               y2="1"
               gradientUnits="objectBoundingBox"
            >
               <stop offset={0} stopColor="#ffd336" />
               <stop offset={filled ?? 0} stopColor="#ffd336" />
               <stop offset={filled ?? 0} stopColor="#ffd336" stopOpacity="0" />
               <stop offset={1} stopColor="#ffd336" stopOpacity="0" />
            </linearGradient>
         </defs>
         <g id="Stars-2" data-name="Stars" transform="translate(0 0)">
            <path
               id="stars_bg"
               data-name="stars bg"
               d="M4.6,4,6.409.351a.636.636,0,0,1,1.137,0L9.359,4l4.053.589a.629.629,0,0,1,.351,1.076L10.831,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9-3.625,1.9a.634.634,0,0,1-.92-.665L3.125,8.5.192,5.666A.629.629,0,0,1,.543,4.591Zm15.35,0L21.759.351a.636.636,0,0,1,1.137,0L24.709,4l4.053.589a.629.629,0,0,1,.351,1.076L26.181,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9L18.7,13.18a.634.634,0,0,1-.92-.665L18.475,8.5,15.542,5.666a.629.629,0,0,1,.351-1.075ZM35.3,4,37.109.351a.636.636,0,0,1,1.137,0L40.059,4l4.053.589a.629.629,0,0,1,.351,1.076L41.531,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9-3.625,1.9a.634.634,0,0,1-.92-.665L33.825,8.5,30.892,5.666a.629.629,0,0,1,.351-1.075Zm15.35,0L52.459.351a.636.636,0,0,1,1.137,0L55.409,4l4.053.589a.629.629,0,0,1,.351,1.076L56.881,8.5l.692,4.012a.634.634,0,0,1-.921.664l-3.624-1.9L49.4,13.18a.634.634,0,0,1-.92-.665L49.175,8.5,46.242,5.666a.629.629,0,0,1,.351-1.075ZM66,4,67.809.351a.636.636,0,0,1,1.137,0L70.759,4l4.053.589a.629.629,0,0,1,.351,1.076L72.231,8.5l.692,4.012A.634.634,0,0,1,72,13.18l-3.624-1.9-3.625,1.9a.634.634,0,0,1-.92-.665L64.525,8.5,61.592,5.666a.629.629,0,0,1,.351-1.075Z"
               fill="#dcdcdc"
            />
            <g
               id="stars-3"
               data-name="stars"
               clipPath={`url(#clip-path-${starsId})`}
            >
               <rect
                  id="gradient"
                  width="75.355"
                  height="13.253"
                  fill={`url(#linear-gradient-${gradientId})`}
               />
            </g>
         </g>
      </svg>
   )
}

export default StarIconScalable
