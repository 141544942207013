import React from 'react'
import { useQuery } from 'react-apollo'
import homeCategoriesQuery from './HomeCategoriesQuery.gql'
import { styled } from 'linaria/react'
import { useJetshopWindowFunctions } from '../../../hooks/useJetshopWindowFunctions'
import WarningBanner from './WarningBanner'
import TopBanner from './TopBanner'
import ActionHeader from './ActionHeader'
import MenuHeader from './MenuHeader'
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings'
import { VB_DESKTOP_MENU, VB_DESKTOP_MENU_CATEGORY, VB_MENU_FEATURED_CATEGORY, VB_MOBILE_MENU, VB_WARNING_BANNER } from '../../CustomElements/elementTypes'
import MobileSearchBar from './MobileSearchBar'
import { HeaderProvider } from '../Header/HeaderContext/HeaderContext'
import SearchResult from './SearchResult'

const StickyHeaderContainer = styled.header`

   .wrapper {
      /* box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07); */
      
      border-radius: 0 0 2.5rem 2.5rem;
   }
`
export default function Header() {
   const [getSetting, loading] = useSiteSettingsQuery();
   const menuCategories = getSetting(null, VB_DESKTOP_MENU)?.children?.filter(c => c.type == VB_DESKTOP_MENU_CATEGORY);
   const featuredCategories = getSetting(null, VB_DESKTOP_MENU)?.children?.filter(c => c.type == VB_MENU_FEATURED_CATEGORY);
   const warningBannerData = getSetting(null, VB_WARNING_BANNER);
   const mobileMenuItems = getSetting(null, VB_MOBILE_MENU);

   const result = useQuery(homeCategoriesQuery, {
      variables: {
         levels: 1,
      },
   })

   const { categories } = result?.data ?? [];

   useJetshopWindowFunctions()

   return (
      <HeaderProvider>
         <WarningBanner data={warningBannerData}></WarningBanner>
         <TopBanner />
         <StickyHeaderContainer className='sticky-top'>
            <div className='container-fluid p-0 m-0 wrapper'>
               <ActionHeader categories={categories} mobileMenuItems={mobileMenuItems}></ActionHeader>
               <SearchResult></SearchResult>
               <MenuHeader categories={categories} cmsCategories={menuCategories} featuredCategories={featuredCategories}></MenuHeader>
            </div>
            <MobileSearchBar></MobileSearchBar>
         </StickyHeaderContainer>
      </HeaderProvider>
   )
}
