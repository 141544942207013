import { useContext, useState, useCallback, useRef } from "react"
import { SiteContext } from "../../Global/SiteContext"
import { DELAY_MS } from "../Util/Constants";

const UseSearchLogic = (inputRef) => {
    const { handleSearchbarChange, performSearch } = useContext(SiteContext)
    const [currentSearchValue, setCurrentSearchValue] = useState("")
    const searchDebounceTimeout = useRef(null);

    const debouncedSetSearchTerm = useCallback((term) => {
        if (searchDebounceTimeout.current) {
            clearTimeout(searchDebounceTimeout.current);
        }
        searchDebounceTimeout.current = setTimeout(() => {
            handleSearchbarChange(term);
        }, DELAY_MS.DEBOUNCE_SEARCH);
    }, [handleSearchbarChange]);

    const handleInputChange = event => {
        const newTerm = event.target.value
        setCurrentSearchValue(newTerm)
        debouncedSetSearchTerm(newTerm)
     }

    const handleInputFocus = () => {
      const cachedValue = inputRef.current.value;
      setCurrentSearchValue(cachedValue)
      handleSearchbarChange(cachedValue)
    }

    const handleKeyPressed = (event) => {
      if (event.key === 'Enter') {
        // Wait for possible exact match or redirect to be returned from the multiSearchRequest
        setTimeout(() => {
          performSearch(currentSearchValue);
          setCurrentSearchValue("");
        }, DELAY_MS.DIRECT_SEARCH);
      }
    };

    const handleClear = (clearValue = "") => {
      if (inputRef.current) {
         inputRef.current.value = clearValue;
         setCurrentSearchValue(clearValue)
         debouncedSetSearchTerm(clearValue)
         inputRef.current.focus()
      }
    };

    return ({handleInputChange, handleInputFocus, handleKeyPressed, handleClear});
}
 
export default UseSearchLogic;