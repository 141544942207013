import DynamicRoute from '@jetshop/core/components/DynamicRoute'
import CustomFont from '@jetshop/core/components/Fonts/CustomFont'
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider'
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext'
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'
import GenericError from '@jetshop/ui/ErrorBoundary/Generic'
import LoadingBar from '@jetshop/ui/Loading/LoadingBar'
import ModalProvider from '@jetshop/ui/Modal/ModalProvider'
import ModalRoot from '@jetshop/ui/Modal/ModalRoot'
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler'
import React, { useContext } from 'react'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { Helmet } from 'react-helmet-async'
import loadable from '@loadable/component'
import { Route, Switch } from 'react-router-dom'
import loadFontCss from '../fonts/loadFontCss'
import CategoryPreviewQuery from './CategoryPreviewQuery.gql'
import PagePreviewQuery from './PagePreviewQuery.gql'
import Content from './Layout/Content'
import Footer from './Layout/Footer/Footer'
import Header from './Layout/Header/Header'
import LoadingPage from './LoadingPage'
import { productListQueries } from './ProductList/productListQueries'
import ProductPreviewQuery from './ProductPreviewQuery.gql'
import routeQuery from './RouteQuery.gql'
import { theme } from './Theme'
import { WithSiteContext } from './Global/SiteContext'
import { useSiteSettingsQuery } from '../hooks/useSiteSettings'
import Overlay from './Global/Overlay'
import '../styles/bootstrap-5.3.0.css'
import '../styles/margin.css'
import '../styles/padding.css'
import '../styles/global.css'
import { UET_SW, UET_DK, UET_NO } from '../utils/scripts/uet'
import { useVoyadoContactId } from './Voyado/hooks/useVoyadoContactId'

const Store = loadable(() => import('./Store/Store'), {
   fallback: <LoadingPage />,
})

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
   fallback: <LoadingPage />,
})

const NotFound = loadable(() => import('./NotFoundPage'), {
   fallback: <LoadingPage />,
})

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
   fallback: <LoadingPage />,
})

const LogOutPage = loadable(
   () => import('@jetshop/core/components/AuthContext/LogOut'),
   {
      fallback: <LoadingPage />,
   }
)

const PreviewRoute = loadable(
   () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
   {
      fallback: <LoadingPage />,
   }
)

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
   fallback: <LoadingPage />,
})

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
   fallback: <LoadingPage />,
})

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
   fallback: <LoadingPage />,
})
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
   fallback: <LoadingPage />,
})

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
   fallback: <LoadingPage />,
})

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
   fallback: <LoadingPage />,
})
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
   fallback: <LoadingPage />,
})

export const LoadableProductPage = loadable(
   () => import('./ProductPage/ProductPage'),
   {
      fallback: <LoadingPage />,
   }
)

export const LoadableCategoryPage = loadable(
   () => import('./CategoryPage/CategoryPage'),
   {
      fallback: <LoadingPage />,
   }
)

export const LoadableContentPage = loadable(
   () => import('./ContentPage/ContentPage'),
   {
      fallback: <LoadingPage />,
   }
)

function Shop() {
   const [getSetting, loading] = useSiteSettingsQuery()
   const ahrefs = getSetting('ahrefs');
   const { routes, startPageId } = useShopConfig()
   const selectedChannel = useContext(ChannelContext)?.selectedChannel
   useVoyadoContactId()

   return (
      <GenericError>
         <ModalProvider>
            <LoadingBar color={theme.colors.loadingBar} />
            <CustomFont
               primaryFont={theme.fonts.primary}
               secondaryFonts={theme.fonts.secondary}
               injectCss={loadFontCss}
            />
            <Helmet
               titleTemplate="%s Verktygsboden"
               defaultTitle="Verktygsboden"
            >
               <meta name="ahrefs-site-verification" content={ahrefs} />
               {selectedChannel?.id === 1 && (
                  <>
                     <script type="text/javascript">{UET_SW}</script>
                     {/* <script type="text/javascript">{metaPixelSw.metaPixelScript}</script>
                        <noscript dangerouslySetInnerHTML={{ __html: metaPixelSw.noScriptImgPixelElement }} /> */}
                  </>
               )}
               {selectedChannel?.id === 2 && (
                  <>
                     <script type="text/javascript">{UET_DK}</script>
                     {/* <script type="text/javascript">{metaPixelDk.metaPixelScript}</script>
                        <noscript dangerouslySetInnerHTML={{ __html: metaPixelDk.noScriptImgPixelElement }} /> */}
                  </>
               )}
               {selectedChannel?.id === 3 && (
                  <>
                     <script type="text/javascript">{UET_NO}</script>
                     {/* <script type="text/javascript">{metaPixelNo.metaPixelScript}</script>
                        <noscript dangerouslySetInnerHTML={{ __html: metaPixelNo.noScriptImgPixelElement }} /> */}
                  </>
               )}
            </Helmet>
            <ProductListProvider queries={productListQueries}>
               <WithSiteContext>
                  <Overlay></Overlay>
                  <Header />
                  <Content>
                     <PaginationProvider defaultProductsPerPage={24}>
                        <Switch>
                           <Route exact path="/">
                              <LoadableStartPage startPageId={startPageId} />
                           </Route>
                           <Route path={routes.search.path}>
                              <LoadableSearchPage />
                           </Route>
                           <Route path={routes.signup.path}>
                              <LoadableSignUpPage />
                           </Route>
                           <Route path={routes.login.path}>
                              {selectedChannel?.id !== 3 ? (<LogInPage />) : (<LoadableStartPage startPageId={startPageId} />)}
                           </Route>
                           <Route path={routes.logout.path}>
                              {selectedChannel?.id !== 3 ? (<LogOutPage />) : (<LoadableStartPage startPageId={startPageId} />)}
                           </Route>
                           <Route path={routes.stores.path}>
                              <StoreLocator />
                           </Route>
                           <Route path={`${routes.store.path}/:id`}>
                              <Store />
                           </Route>
                           <Route path={routes.tree.path}>
                              <NavTreePage />
                           </Route>
                           <Route path={routes.myPages.path}>
                              {selectedChannel?.id !== 3 ? (<LoadableMyPages />) : (<LoadableStartPage startPageId={startPageId} />)}
                           </Route>
                           <Route exact path={routes.forgotPassword.path}>
                              {selectedChannel?.id !== 3 ? (<ForgotPassword />) : (<LoadableStartPage startPageId={startPageId} />)}
                           </Route>
                           <Route
                              path={`${routes.resetPassword.path}/:token`}
                           >
                              {selectedChannel?.id !== 3 ? (<ResetPassword />) : (<LoadableStartPage startPageId={startPageId} />)}
                           </Route>
                           <Route
                              path="/preview"
                              render={props => (
                                 <PreviewRoute
                                    productQuery={ProductPreviewQuery}
                                    categoryQuery={CategoryPreviewQuery}
                                    pageQuery={PagePreviewQuery}
                                    productPage={LoadableProductPage}
                                    categoryPage={LoadableCategoryPage}
                                    contentPage={LoadableContentPage}
                                    StartPage={LoadableStartPage}
                                    {...props}
                                 />
                              )}
                           />
                           <DynamicRoute
                              routeQuery={routeQuery}
                              productPage={LoadableProductPage}
                              categoryPage={LoadableCategoryPage}
                              contentPage={LoadableContentPage}
                              notFoundPage={NotFound}
                              LoadingPage={LoadingPage}
                           />
                        </Switch>
                     </PaginationProvider>
                  </Content>
                  <Footer />
               </WithSiteContext>
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
               ignoreForRouteTypes={[
                  'sortOrderChange',
                  'filterChange',
                  'paginationChange',
                  'FindifyUpdate',
               ]}
            />
         </ModalProvider>
      </GenericError>
   )
}

export default Shop



