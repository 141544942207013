import { isCampaignStillActive } from "../../Core/Util/Helpers";

export const formatVectoryProduct = product => ({
   title: product?.name,
   brandName: product?.brandName,
   brandCategoryId: product?.brandCategoryId,
   articleNumber: product?.itemNo,
   imageUrl: product?.imageUrl,
   imageSmallUrl: product?.imageSmallUrl || product?.imageUrl,
   url: product?.url,
   variants: product?.variants,
   ean: product?.ean,
   isPreBook: product?.prebook,
   onSale: product?.isCampaign && isCampaignStillActive(product?.campaignToDate),
   buyable: product?.buyable,
   campaignFromDate: product?.campaignFromDate,
   campaignToDate: product?.campaignToDate,
   ...( product?.isCampaign && isCampaignStillActive(product?.campaignToDate)
      ? {
           currentPrice: product?.price?.campaignPriceIncVat,
           oldPrice: product?.price?.incVat,
           exVatCurrentPrice: product?.price?.campaignPriceExVat,
           exVatOldPrice: product?.price?.exVat,
        }
      : {
           currentPrice: product?.price?.incVat,
           exVatCurrentPrice: product?.price?.exVat,
        }),
   ...(product?.isPackage ? {
      oldPrice: product?.price?.incVat,
      exVatOldPrice: product?.price?.exVat,
      currentPrice: product?.price?.campaignPriceIncVat,
      exVatCurrentPrice: product?.price?.campaignPriceExVat,
   } : {}),
   // Status flags
   stockStatusMissing: !product?.buyable,
   hasVariants: product?.hasVariants,
   isNew: product?.isNew,
   isPackage: product?.isPackage,
   // inStock: product?.inStock,
   // Ratings and scores
   testFreakRating: product?.testFreakRating,
   popularityScore: product?.popularityScore,
   // Related content
   specifications: product?.specifications,
   manuals: product?.manuals,
   videos: product?.videos,
   accessories: product?.accessories,
   spareParts: product?.spareParts,
   // Categories
   categories: product?.categories,
   // isDigifiSearchProduct: product?.isDigifiSearchProduct,
})