import { DIGIFI_FILTER_FIELD_NAMES, DIGIFI_SORT_VALUES } from '../../DigifiSearch/constants'
import { VECTORY_FILTER_OPERATORS, VECTORY_SORT_VALUES } from '../Constants'
import {
   adaptSortFieldValues,
   mapFilterConditions,
} from '../FilterAdapters/VectoryFilterAdapters'

const API_URL_SEARCH_STANDARD = 'https://plenum.digifiservices.se/prod/vectory/api/search/standard'
const BASE_API_URL_SEARCH_TERMS = 'https://plenum.digifiservices.se/prod/vectory/api/search/search-terms'
const API_KEY = 'ecd8b4ce-af7b-4980-afd8-f6b5727e8f2c'
const DEFAULT_PRODUCT_SORT_FIELDS = [VECTORY_SORT_VALUES.IN_STOCK_DESC, VECTORY_SORT_VALUES.POPULARITY_SCORE_DESC]

const createHeaders = () => {
   return new Headers({
      Accept: '*/*',
      'X-Api-Key': API_KEY,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, X-Api-Key'
   })
}

const sendVectoryStandardSearchRequest = async payload => {
   if (!payload || typeof payload !== 'object') {
      throw new Error('Invalid requestObject provided. Must be an object.')
   }

   const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(payload),
      redirect: 'follow',
   }

   try {
      const response = await fetch(API_URL_SEARCH_STANDARD, requestOptions)

      if (!response.ok) {
         console.error('Error response details from VectoryStandardSearchRequest:', {
            status: response?.status,
            statusText: response?.statusText,
            url: response?.url,
         })
         throw new Error(`Request failed with status: ${response.status}`)
      }
      return await response.json()
   } catch (error) {
      console.error('Error in sendVectoryStandardSearchRequest:', {
         message: error?.message,
         stack: error?.stack,
      })
      throw error
   }
}

const getVectorySearchTermsRequest = async ({ searchTerm, schemaName, maxResults }) => {
   const baseUrl = BASE_API_URL_SEARCH_TERMS;
   const queryParams = new URLSearchParams({ searchTerm, schemaName, take: maxResults }).toString();
   const url = `${baseUrl}?${queryParams}`;

   try {
      const response = await fetch(url, {
         method: "GET",
         headers: createHeaders(),
      });

      if (!response.ok) {
         console.error("Error response details from VectorySerchTermsRequest:", {
            status: response.status,
            statusText: response.statusText,
            url: response.url,
         });
         throw new Error(`Request failed with status: ${response.status}`);
      }

      return await response.json();
   } catch (error) {
      console.error("Error in getVectorySearchTermsRequest:", {
         message: error.message,
         stack: error.stack,
      });
      throw error;
   }
};

const getCategoryByCatId = async ({ categoryId, collection }) => {
   try {
      let payload = {
         collection,
         defaultFilterConditions: {
            id: [
               {
                  value: categoryId?.toString(),
                  operator: VECTORY_FILTER_OPERATORS.EQUAL,
               },
            ],
         },
      }
      const response = await sendVectoryStandardSearchRequest(payload)
      return response
   } catch (error) {
      console.error('Error in getCategoryByCatId:', {
         message: error.message,
         stack: error.stack,
      })
      throw error
   }
}

const getCategoryByCatIds = async ({ categoryIds, collection }) => {
   try {
      let payload = {
         collection,
         filterConditions: {
            id: categoryIds.map(id => ({
               value: `${id}`,
               operator: VECTORY_FILTER_OPERATORS.EQUAL
            }))
         },
         facetingRules: {
            id: ["disjunctive"]
         }
      }
      const response = await sendVectoryStandardSearchRequest(payload)
      return response
   } catch (error) {
      console.error('Error in getCategoryByCatIds:', {
         message: error.message,
         stack: error.stack,
      })
      throw error
   }
}

const getCategoriesBySearchTerm = async ({
   collection,
   searchTerm,
   sortBy,
   pageSize = 6,
}) => {
   try {
      let payload = {
         collection: collection,
         searchTerm: searchTerm,
         pageSize: pageSize,
         ...(Array.isArray(sortBy) && sortBy.length > 0 && { sortByFields: sortBy })
      }
      const response = await sendVectoryStandardSearchRequest(payload)
      return response
   } catch (error) {
      console.error('Error in getCategoriesBySearchTerm:', {
         message: error.message,
         stack: error.stack,
      })
      throw error
   }
}

const getBrandsBySearchTerm = async ({
   collection,
   searchTerm,
   sortBy,
   pageSize = 6,
}) => {
   try {
      let payload = {
         collection: collection,
         searchTerm: searchTerm,
         pageSize: pageSize,
         ...(Array.isArray(sortBy) && sortBy.length > 0 && { sortByFields: sortBy })
      }
      const response = await sendVectoryStandardSearchRequest(payload)
      return response
   } catch (error) {
      console.error('Error in getBrandsBySearchTerm:', {
         message: error.message,
         stack: error.stack,
      })
      throw error
   }
}

const getProductsBySearchTerm = async ({
   collection,
   searchTerm,
   sortBy,
   overrideDefaultSortByFields = false,
   pageSize = 48,
   filters,
   defaultFilters = [],
}) => {
   const filterConditions = mapFilterConditions(filters)
   const defaultFilterConditions = mapFilterConditions(defaultFilters)
   const useSortByFields = adaptSortFieldValues(sortBy)
   
   const sortByFields = overrideDefaultSortByFields 
      ? (useSortByFields || []) 
      : [...DEFAULT_PRODUCT_SORT_FIELDS, ...(useSortByFields || [])]

   const payload = {
      collection,
      searchTerm: searchTerm,
      pageSize: pageSize,
      page: 1,
      ...(Array.isArray(sortByFields) && sortByFields.length > 0 && { sortByFields }),
      ...(defaultFilterConditions && Object.keys(defaultFilterConditions).length > 0 && { defaultFilterConditions }),
      ...(filterConditions && Object.keys(filterConditions).length > 0 && { filterConditions }),
   }

   try {
      const response = await sendVectoryStandardSearchRequest(payload)
      return response
   } catch (error) {
      console.error('Error in vectory service getProductsBySearchTerm:', error)
      throw error
   }
}

const getBrandsByNames = async ({ brandNames, collection }) => {
   try {
      const payload = {
         collection,
         pageSize: 6,
         page: 1,
         facetsToDisplay: [],
         filterConditions: {
            name: Array.from(brandNames).map(name => ({
               value: `'${name}'`,
               operator: VECTORY_FILTER_OPERATORS.EQUAL
            }))
         },
         facetingRules: {
            name: ["disjunctive"]
         }
      }

      return await sendVectoryStandardSearchRequest(payload)
   } catch (error) {
      console.error('Error in getBrandsByNames:', error)
      throw error
   }
}

const getBrandsByBrandCategoryIds = async ({ brandCategoryIds, collection, sortBy }) => {
   try {
      const payload = {
         collection,
         pageSize: 6,
         page: 1,
         facetsToDisplay: [],
         filterConditions: {
            id: brandCategoryIds.map(id => ({
               value: `${id}`,
               operator: VECTORY_FILTER_OPERATORS.EQUAL
            }))
         },
         facetingRules: {
            id: ["disjunctive"]
         },
         ...(Array.isArray(sortBy) && sortBy.length > 0 && { sortByFields: sortBy })
      }

      return await sendVectoryStandardSearchRequest(payload)
   } catch (error) {
      console.error('Error in getBrandsByNames:', error)
      throw error
   }
}

const getProductByItemNo = async ({ itemNo, collection }) => {
   try {
      const payload = {
         collection,
         pageSize: 1,
         page: 1,
         filterConditions: {
            itemNo: [{
               value: itemNo,
               operator: VECTORY_FILTER_OPERATORS.EQUAL
            }]
         }
      }
      return await sendVectoryStandardSearchRequest(payload)
   } catch (error) {
      console.error('Error in getProductByItemNo:', error)
      throw error
   }
}

const getProductByVariantNo = async ({ itemNo, collection }) => {
   try {
      const payload = {
         collection,
         pageSize: 1,
         page: 1,
         filterConditions: {
            itemNos: [{
               value: itemNo,
               operator: VECTORY_FILTER_OPERATORS.EQUAL
            }]
         }
      }
      return await sendVectoryStandardSearchRequest(payload)
   } catch (error) {
      console.error('Error in getProductByVariantNo:', error)
      throw error
   }
}

const getProductsByItemNos = async ({ itemNos, collection, pageSize, disableSorting = false }) => {
   try {
      const payload = {
         collection,
         pageSize: pageSize || 48,
         page: 1,
         filterConditions: {
            itemNo: itemNos.map(itemNo => ({
               value: itemNo,
               operator: VECTORY_FILTER_OPERATORS.EQUAL
            }))
         },
         facetingRules: {
            itemNo: ['disjunctive']
         },
         sortByFields: disableSorting ? [] : DEFAULT_PRODUCT_SORT_FIELDS
      }
      return await sendVectoryStandardSearchRequest(payload)
   } catch (error) {
      console.error('Error in getProductByItemNos:', error)
      throw error
   }
}

const getProductsByFilters = async ({
   collection,
   filters,
   defaultFilters = [],
   defaultSearchQuery,
   showVat
}) => {
   const filterConditions = mapFilterConditions(filters, showVat)
   const defaultFilterConditions = mapFilterConditions(defaultFilters, showVat)
   const searchTerm = defaultSearchQuery || filters?.find(filter => filter?.fieldName === DIGIFI_FILTER_FIELD_NAMES.SEARCH_QUERY)?.value
   const pageSize = filters.find(filter => filter?.fieldName === DIGIFI_FILTER_FIELD_NAMES.PAGE_SIZE)?.value;
   const page = filters?.find(filter => filter?.fieldName === DIGIFI_FILTER_FIELD_NAMES.PAGE)?.value;
   const defaultFacetsToDisplay = ['filterCategories', 'brandName', 'prebook', 'priceIncVat', 'priceExVat', 'isNew', "isCampaign"]
   const defaultFacetingRules = {
      brandName: ['disjunctive'],
      priceIncVat: ['defaultStats'],
      priceExVat: ['defaultStats']
   }

   const sortField = filters?.find(filter => filter?.fieldName === DIGIFI_FILTER_FIELD_NAMES.SORT_BY)?.value;
   const isPriceSorting = sortField === DIGIFI_SORT_VALUES.PRICE_ASC || sortField === DIGIFI_SORT_VALUES.PRICE_DESC;
   const selectedSortFieldArr = adaptSortFieldValues(
      sortField
   ) ?? [];

   const sortByFields = selectedSortFieldArr.length
      ? isPriceSorting
         ? [DEFAULT_PRODUCT_SORT_FIELDS[0], ...selectedSortFieldArr]
         : [DEFAULT_PRODUCT_SORT_FIELDS, ...selectedSortFieldArr]
      : DEFAULT_PRODUCT_SORT_FIELDS;

   const payload = {
      collection, 
      searchTerm: searchTerm || "*",
      pageSize: pageSize || 48,
      page: page || 1,
      facetsToDisplay: Array.isArray(defaultFacetsToDisplay) ? defaultFacetsToDisplay : [],
      sortByFields: Array.isArray(sortByFields) ? sortByFields : [],
      defaultFilterConditions: defaultFilterConditions ?? {},
      filterConditions: filterConditions ?? {},
      facetingRules: defaultFacetingRules ?? {},
   };

   try {
      const response = await sendVectoryStandardSearchRequest(payload)
      return response
   } catch (error) {
      console.error('Error in vectory service getProductsByFilters:', error)
      throw error
   }
}

const getSearchTerms = async ({
   searchTerm,
   maxResults = 6,
   schemaName
}) => {
   if (!searchTerm) {
      return
   }
   try {
      const response = await getVectorySearchTermsRequest({ searchTerm, schemaName, maxResults })
      return response
   } catch (error) {
      console.error('Error in getSearchTerms:', {
         message: error.message,
         stack: error.stack,
      })
      throw error
   }
}

export const vectoryService = {
   getProductsByFilters,
   getProductsBySearchTerm,
   getSearchTerms,
   getCategoryByCatId,
   getCategoriesBySearchTerm,
   getBrandsBySearchTerm,
   getBrandsByNames,
   getBrandsByBrandCategoryIds,
   getProductByItemNo,
   getProductsByItemNos,
   getProductByVariantNo,
   getCategoryByCatIds
}