import { escapeSingleQuotes } from '../../Core/Util/Helpers'
import { DIGIFI_FILTER_FIELD_NAMES, DIGIFI_SORT_VALUES } from '../../DigifiSearch/constants'
import { VECTORY_FILTER_FIELD_NAMES, VECTORY_FILTER_OPERATORS, VECTORY_SORT_VALUES } from '../Constants'

const EXCLUDED_FIELDS = [
   DIGIFI_FILTER_FIELD_NAMES.SEARCH_QUERY,
   DIGIFI_FILTER_FIELD_NAMES.PAGE_SIZE,
   DIGIFI_FILTER_FIELD_NAMES.PAGE,
   DIGIFI_FILTER_FIELD_NAMES.SORT_BY,
   "q"
]

/**
 * Gets the appropriate operator for a given field name
 * @param {string} fieldName - The name of the field to get the operator for
 * @returns {string} The operator for the given field name
 */
const getOperator = fieldName => {
   switch (fieldName) {
      default:
         return VECTORY_FILTER_OPERATORS.EQUAL
   }
}

/**
 * Adapts sort field values from Digifi format to Vectory API format
 * @param {string|string[]|null} sortValues - Sort values to adapt. Can be a single string, array of strings, or null
 * @returns {string[]} Array of adapted sort field values in Vectory format
 */
export const adaptSortFieldValues = sortValues => {
   const fieldNameMap = {
      [DIGIFI_SORT_VALUES.PRICE_ASC]: VECTORY_SORT_VALUES.PRICE_INC_VAT_ASC,
      [DIGIFI_SORT_VALUES.PRICE_DESC]: VECTORY_SORT_VALUES.PRICE_INC_VAT_DESC,
   }
   // Handle empty, string, or array input
   let sortValuesArr = []
   if (sortValues) {
      if (typeof sortValues === 'string') {
         sortValuesArr = [sortValues]
      } else if (Array.isArray(sortValues)) {
         sortValuesArr = sortValues
      }
   }

   return sortValuesArr.map(value => fieldNameMap[value] || value)
}

/**
 * Adapts field names from Digifi format to Vectory API format
 * @param {Object} formattedFilters - Object containing filters with Digifi field names
 * @param {boolean} showVat - Flag indicating whether to use VAT inclusive or exclusive price field
 * @returns {Object} Object with field names mapped to Vectory API format
 */
const adaptFieldNames = (formattedFilters, showVat) => {
   const fieldNameMap = {
      // [DIGIFI_FILTER_FIELD_NAMES.PRICE]: VECTORY_FILTER_FIELD_NAMES.PRICE_INC_VAT,
      [DIGIFI_FILTER_FIELD_NAMES.PRICE]: showVat ? VECTORY_FILTER_FIELD_NAMES.PRICE_INC_VAT : VECTORY_FILTER_FIELD_NAMES.PRICE_EX_VAT,
      [DIGIFI_FILTER_FIELD_NAMES.BRAND]: VECTORY_FILTER_FIELD_NAMES.BRAND_NAME,
   }

   return Object.keys(formattedFilters).reduce((updatedFilters, key) => {
      const newKey = fieldNameMap[key] || key
      updatedFilters[newKey] = formattedFilters[key]
      return updatedFilters
   }, {})
}


/**
 * Maps filter conditions from Digifi format to Vectory API format
 * @param {Array<{fieldName: string, value: string|string[]|number[]}>} filters - Array of filter objects containing fieldName and value
 * @param {boolean} showVat - Flag indicating whether to use VAT inclusive or exclusive price fields
 * @returns {Object} Object containing mapped filter conditions in Vectory API format
 */
export const mapFilterConditions = (filters, showVat) => {
   const formattedFilters = filters?.reduce(
      (filterResult, { fieldName, value }) => {
         if (EXCLUDED_FIELDS.includes(fieldName)) return filterResult
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.PRICE && Array.isArray(value)) {
            filterResult[fieldName] = [
               {
                  value: Math.round(parseFloat(value[0])).toString(),
                  operator: VECTORY_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
               },
               {
                  value: Math.round(parseFloat(value[1])).toString(),
                  operator: VECTORY_FILTER_OPERATORS.LESS_THAN_OR_EQUAL
               },
            ]
            return filterResult
         }
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.BRAND) {
            if (!Array.isArray(value)) {
               filterResult[fieldName] = [{
                  value: `'${escapeSingleQuotes(value.toString())}'`,
                  operator: getOperator(fieldName),
               }]
            } else {
               filterResult[fieldName] = value.map(v => ({
                  value: `'${escapeSingleQuotes(v.toString())}'`,
                  operator: getOperator(fieldName),
               }))
            }
            return filterResult
         }
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.BRAND_CATEGORY_ID) {
            filterResult[fieldName] = [{
               value: value?.toString(),
               operator: getOperator(fieldName),
            }]
            return filterResult
         }
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.PREBOOK) {
            filterResult[fieldName] = [{
               value: value?.toString(),
               operator: getOperator(fieldName),
            }]
            return filterResult
         }
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.IS_NEW) {
            filterResult[fieldName] = [{
               value: value?.toString(),
               operator: getOperator(fieldName),
            }]
            return filterResult
         }
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.IS_CAMPAIGN) {
            filterResult[fieldName] = [{
               value: value?.toString(),
               operator: getOperator(fieldName),
            }]
            return filterResult
         }
         if (fieldName === DIGIFI_FILTER_FIELD_NAMES.FILTER_CATEGORIES) {
            filterResult[fieldName] = [{
               value: value?.toString(),
               operator: getOperator(fieldName),
            }]
            return filterResult
         }

         return filterResult
      },
      {}
   )

   return adaptFieldNames(formattedFilters, showVat)
}
