const normalizePrice = (price) =>
   Number.isFinite(parseFloat(price)) ? Math.ceil(parseFloat(price)) : undefined;

// Default empty response structure
export const getEmptySearchProductsResponse = () => ({
   products: [],
   facets: {
      brandFacets: [],
      prebookFacets: [],
      priceFacets: null,
   },
   metaData: {
      totalCount: 0,
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      searchTimeMs: 0,
   },
})

export const ensureSearchProductsResponse = (response) => {
   const facets = Object.entries(response?.facetDistribution || {}).reduce(
      (acc, [field, values]) => {
         switch (field) {
            case 'brandName':
               acc.brandFacets = values?.map(f =>
                  ensureFacetStructure(f)
               )
               break
            case 'prebook':
               acc.prebookFacets = values?.map(f =>
                  ensureFacetStructure(f)
               )
               break
            default:
               break
         }
         return acc
      },
      {
         brandFacets: [],
         prebookFacets: [],
      }
   )
   
   const priceFacets = ensurePriceFacetStructure(response?.facetStats)

   const metaData = {
      totalCount: response?.totalResults ?? 0,
      currentPage: response?.page ?? 0,
      pageSize: response?.pageSize ?? 0,
      totalPages: Math.ceil(
         (response?.totalResults ?? 0) / (response?.pageSize ?? 1)
      ),
      searchTimeMs: response?.searchTimeMs ?? 0,
   }

   return {
      products:
         response?.results?.map(result =>
            ensureProductStructure(result?.payload)
         ) || [],
      facets: {
         ...facets,
         priceFacets,
      },
      metaData,
   }
}


export const ensureProductStructure = (product = {}) => ({
   id: product?.id,
   name: product?.name,
   description: product?.description,
   itemNo: product?.itemNo,
   ean: product?.ean,
   prebook: product?.prebook,
   onlyForSaleInStore: product?.onlyForSaleInStore,
   campaignFromDate: product?.campaignFromDate,
   campaignToDate: product?.campaignToDate,
   testFreakRating: product?.testFreakRating,
   popularityScore: product?.popularityScore,
   imageUrl: product?.imageUrl ?? '',
   imageSmallUrl: product?.imageSmallUrl ?? '',
   url: product?.path ?? '',
   isCampaign: product?.isCampaign,
   price: {
      incVat: normalizePrice(product?.priceIncVat),
      exVat: normalizePrice(product?.priceExVat),
      campaignPriceIncVat: normalizePrice(product?.campaignPriceIncVat),
      campaignPriceExVat: normalizePrice(product?.campaignPriceExVat),
   },
   isPackage: product?.isPackage,
   buyable: product?.buyable,
   brandName: product?.brandName,
   brandCategoryId: product?.brandCategoryId,
   isNew: product?.isNew,
   hasVariants: product?.hasVariants,
   variants: product?.variants ?? [],
   manuals: product?.manuals ?? [],
   specifications: product?.specifications ?? [],
   videos: product?.videos ?? [],
   accessories: product?.accessories ?? [],
   spareParts: product?.spareParts ?? [],
   filterCategories: product?.filterCategories ?? [],
   categories: Array.isArray(product?.categories)
   ? product.categories.map(category => ({
        id: category?.Id,
        name: category?.Name,
        path: category?.Path,
     }))
   : [],
   bundleProducts: product?.bundleProducts ?? [],
   inStock: product?.inStock,
   // Manually added flag to identify DigifiSearch products
   isDigifiSearchProduct: true,
});

export const ensureBrandNameStructure = (brandItem = {}) => ({
   id: brandItem?.id,
   isHidden: brandItem?.isHidden,
   name: brandItem?.name,
   path: brandItem?.path,
   popularityScore: brandItem?.popularityScore,
});

export const ensureCategoryStructure = (category = {}) => {
   const processSubCategory = subCategory => ({
      id: subCategory?.id,
      name: subCategory?.name,
      path: subCategory?.path,
      isHidden: subCategory?.isHidden,
      level: subCategory?.level,
      hasSubCategories: subCategory?.hasSubCategories,
      subCategories: Array.isArray(subCategory?.subCategories)
         ? subCategory.subCategories.map(processSubCategory)
         : [],
   })

   return {
      id: category?.id,
      name: category?.name,
      path: category?.path,
      isHidden: category?.isHidden,
      parentId: category?.parentId,
      level: category?.level,
      parentPath: category?.parentPath,
      hasSubCategories: category?.hasSubCategories,
      topLevelId: category?.topLevelExternalId,
      popularityScore: category?.popularityScore,
      subCategories: Array.isArray(category?.subCategories)
         ? category.subCategories.map(processSubCategory)
         : [],
   }
}


export const ensureProductQueryStructure = (productQuery = {}) => ({
   id: productQuery?.id,
   q: productQuery?.q,
   count: productQuery?.count || 0,
})


export const ensureBrandStructure = (brand = {}) => ({
   id: brand?.id,
   isHidden: brand?.isHidden,
   name: brand?.name,
   path: brand?.path,
   popularityScore: brand?.popularityScore,
})

export const ensureFacetStructure = (facet = {}) => ({
   count: facet?.count || 0,
   value: facet?.value || '',
})

export const ensurePriceFacetStructure = (facets = {}) => ({
   priceExVat: {
      min: Math.floor(facets?.priceExVat?.min ?? 0),
      max: Math.ceil(facets?.priceExVat?.max ?? 0)
   },
   priceIncVat: {
      min: Math.floor(facets?.priceIncVat?.min ?? 0),
      max: Math.ceil(facets?.priceIncVat?.max ?? 0)
   },
   defaultPriceExVat: {
      min: Math.floor(facets?.defaultPriceExVat?.min ?? 0),
      max: Math.ceil(facets?.defaultPriceExVat?.max ?? 0)
   },
   defaultPriceIncVat: {
      min: Math.floor(facets?.defaultPriceIncVat?.min ?? 0),
      max: Math.ceil(facets?.defaultPriceIncVat?.max ?? 0)
   }
})

export const ensureSearchTermStructure = (searchTerm = {}) => ({
   term: searchTerm?.term,
   searchCount: searchTerm?.searchCount,
   averageResults: searchTerm?.averageResults,
   lastSearched: searchTerm?.lastSearched,
   similarityScore: searchTerm?.similarityScore 
})

export const vectoryMapping = {
   ensureProductStructure,
   ensureBrandNameStructure,
   ensureCategoryStructure,
   ensureProductQueryStructure,
   ensureBrandStructure,
   ensureFacetStructure,
   ensurePriceFacetStructure,
   ensureSearchTermStructure
}
